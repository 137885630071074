import React from "react";

// Constants
import referencesData from "../constants/datas/referencesData";

// Hooks
import useWindowDimensions from "../hooks/useWindowDimensions";

// Components
import { Box, Grid, Typography } from "@mui/material";

const References = () => {
  const { width } = useWindowDimensions();
  const mobileView = width < 760;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingX: mobileView ? 4 : 10,
        paddingBottom: 10,
        paddingTop: mobileView ? 5 : 1,
        backgroundColor: "#f1f1ee",
      }}
    >
      <Typography
        variant={mobileView ? "h4" : "h3"}
        component="h1"
        sx={{
          paddingY: 5,
          fontWeight: "500",
          width: "100%",
          textAlign: "left",
          color: "black",
        }}
      >
        Referanslar
      </Typography>
      <Grid>
        <Grid container>
          {referencesData.map((item) => (
            <Grid
              container
              item
              lg={2}
              md={3}
              xs={4}
              sx={{
                alignItems: "center",
                justifyContent: "center",
                maxHeight: 125,
                padding: mobileView ? 2.25 : 4,
                marginBottom: 4,
                opacity: 0.6,
                transition: "all .4s ease",
                // "-webkit-filter": "grayscale(100%)",
                // filter: "grayscale(100%)",
                "&:hover": {
                  opacity: 1,
                  // "-webkit-filter": "none",
                  // filter: "none",
                },
              }}
              key={item.slice(-6, -4)}
            >
              <img
                src={item}
                alt=""
                width={"100%"}
                height="auto"
                style={{
                  maxWidth: "fit-content",
                  maxHeight: "100%",
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default References;
