import { Box, Button, Typography } from "@mui/material";
import React from "react";

const EysPage = () => {
  return (
    <Box padding={5}>
      <Typography variant="h4" mb={5} textAlign={"center"} color={"#83B630"}>
        ENTEGRE YÖNETİM SİSTEMİ POLİTİKASI
      </Typography>
      <Typography mb={3}>
        Newex Bilgi Teknolojileri ve Danışmanlık A.Ş olarak;
      </Typography>
      <Box mb={2} maxWidth={"75%"}>
        <Typography mb={1}>
          ● Kişisel veri ve bilgi varlıklarını yönetmeyi, varlıkların güvenlik
          değerlerini, ihtiyaçlarını ve risklerini belirlemeyi, güvenlik
          risklerine yönelik kontrolleri geliştirmeyi ve uygulamayı,
        </Typography>
        <Typography mb={1}>
          ● Kişisel veri ile bilgi varlıklarının, değerlerini, güvenlik
          ihtiyaçlarını, zafiyetlerini, varlıklara yönelik tehditleri,
          tehditlerin sıklıklarının saptanması için yöntemleri belirleyerek
          çerçeveyi tanımlamayı,
        </Typography>
        <Typography mb={1}>
          ● Tehditlerin varlıklar ve veriler üzerindeki gizlilik, bütünlük,
          erişilebilirlik etkilerini değerlendirmeye yönelik bir çerçeveyi
          tanımlamayı,
        </Typography>
        <Typography mb={1}>
          ● Risklerin işlenmesi için çalışma esaslarını ortaya koymayı,
        </Typography>
        <Typography mb={1}>
          ● Hizmet verilen kapsam bağlamında teknolojik beklentileri gözden
          geçirerek riskleri sürekli takip etmeyi,
        </Typography>
        <Typography mb={1}>
          ● Tabi olduğu ulusal veya uluslararası düzenlemelerden, yasal ve
          ilgili mevzuat gereklerini yerine getirmekten, anlaşmalardan doğan
          yükümlülüklerini karşılamaktan, iç ve dış paydaşlara yönelik kurumsal
          sorumluluklarından kaynaklanan bilgi güvenliği, kalite ve kişisel veri
          yönetim sistemi gereksinimlerini sağlamayı,
        </Typography>
        <Typography mb={1}>
          ● Hizmet sürekliliğine yönelik tehditlerin etkisini azaltmayı ve
          sürekliliğe katkıda bulunmayı,
        </Typography>
        <Typography mb={1}>
          ● Gerçekleşebilecek veri ve bilgi güvenliği olaylarına hızla müdahale
          edebilecek ve olayın etkisini minimize edecek yetkinliğe sahip olmayı,
        </Typography>
        <Typography mb={1}>
          ● Maliyet etkin bir kontrol altyapısı ile yönetim sistemleri
          seviyesini zaman içinde korumayı ve iyileştirmeyi,
        </Typography>
        <Typography mb={1}>
          ● Kurum itibarını geliştirmeyi, bilgi güvenliği temelli olumsuz
          etkilerden korumayı,
        </Typography>
        <Typography mb={1}>
          ● Bilgi Güvenliği Yönetim Sistemi, Kalite Yönetim Sistemi, Kişisel
          Veri Yönetim Sistemi sürekliliğini sağlamayı,
        </Typography>
        <Typography mb={1}>
          ● Bilgi Güvenliği Yönetim Sistemi, Kalite Yönetim Sistemi, Kişisel
          Veri Yönetim Sistemi uluslararası standardına uygun olarak sürekli
          iyileştirmeyi,
        </Typography>
        <Typography mb={1}>
          ● Tüm Yönetim Sistemlerini planlamayı, oluşturmayı, uygulamayı ve
          korumayı,
        </Typography>
        <Typography mb={1}>
          ● Güvenilir, itibarlı ve sağlam firma imajını koruyarak,
          çalışmalarımıza devam etmeyi,
        </Typography>
        <Typography mb={1}>
          ● Müşterilerimizin beklentilerini, kurum politikalarını ve yasal
          yükümlülükleri dikkate alarak iş sürekliliği planlarımızı hazırlamayı,
        </Typography>
        <Typography mb={1}>
          ● Planlarımızın acil durumlarda çalışmasını garanti altına almak üzere
          düzenli tatbikatlar uygulamayı,
        </Typography>
        <Typography mb={1}>
          ● Hizmet sunumunu hatasız yapmayı hedefleyerek düşük maliyet ile üstün
          bir kalite seviyesine ulaşmak,
        </Typography>
        <Typography>
          ● İç / Dış Müşteriler, Tedarikçiler ve ilgili diğer tüm bağlamlarımız
          ile kurumsal iletişimde teknolojik imkânları kullanarak zamanında,
          hızlı ve etkin çalışan bir sistem oluşturmayı
        </Typography>
      </Box>
      <Typography mb={3}>taahhüt ederiz.</Typography>
      <Typography fontWeight={"900"}>Genel Müdür</Typography>
    </Box>
  );
};

export default EysPage;
