import React from "react";

// Layouts
import ServiceContentLayout from "../../../layouts/ServiceContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const SecurityTestOne = () => {
  const images = [certificate];

  return (
    <ServiceContentLayout
      header={"Sosyal Mühendislik Testi"}
      images={images}
      title={"Güvenlik Testleri"}
    >
      Günümüzde güvenlikteki en zayıf halkayı kullanıcılar oluşturmaktadır.
      Kurumlar IT altyapıları kapsamında ne kadar yatırım yaparlarsa yapsınlar
      kullanıcı farkındalığı sağlanmadığı müddetçe kurum içinde bir güvenlik
      seviyesinden bahsetmek mümkün değildir çünkü bir zincirin gücü en zayıf
      halkası kadardır ve bu siber güvenlik için kesin olarak geçerlidir.
      <br />
      <br />
      Sosyal mühendislik çalışmaları ile bir taraftan kullanıcı farkındalık
      seviyesi karnelenirken diğer taraftan kullanıcı farkındalığı artırılmış
      olur. Sosyal mühendislik çalışmaları aynı zamanda çok iyi bir farkındalık
      artırma aracıdır.
      <br />
      <br />
      NewEx olarak, uzman kadromuzla birlikte belirlediğimiz mail, telefon,
      fiziksel ziyaret ve benzeri birçok senaryo kapsamında sosyal mühendislik
      testleri gerçekleştirmekteyiz.
    </ServiceContentLayout>
  );
};

export default SecurityTestOne;
