import React from "react";

// Components
import { Box, Toolbar } from "@mui/material";
import Navbar from "../components/Navbar";

// Router
import { Outlet } from "react-router-dom";

const MainLayout = ({ children }) => {
  return (
    <Box className="page-container">
      <Navbar />
      <Toolbar />
      <Box className="page-content">
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;
