import React, { useEffect, useState } from "react";

// Components
import { Box, Button, Typography } from "@mui/material";
import useWindowDimensions from "../hooks/useWindowDimensions";

// Router
import { Outlet, useNavigate, useLocation } from "react-router-dom";

// Services Data
import servicesData from "../constants/datas/servicesData";

const ServiceLayout = () => {
  const [state, setState] = useState(null);
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const mobileView = width < 760;
  let { pathname } = useLocation();

  useEffect(() => {
    if (servicesData) {
      if (pathname.includes("/services/security-test")) {
        setState(servicesData[0]);
      }
      if (pathname.includes("/services/security-consulting")) {
        setState(servicesData[1]);
      }
      if (pathname.includes("/services/compatibility")) {
        setState(servicesData[2]);
      }
      if (pathname.includes("/services/trainings")) {
        setState(servicesData[3]);
      }
    }
  }, [pathname]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: mobileView ? "column" : "row",
        padding: 6,
      }}
    >
      <Outlet />
      {pathname.split("/").length >= 3 && state !== null ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            paddingTop: 10,
          }}
        >
          <Button
            key={state.subtitles[0].subtitleName}
            onClick={() =>
              navigate(state.subtitles[0].subRoute, { state: state })
            }
            sx={{
              textTransform: "none",
              color: "#fff",
            }}
          >
            <Typography
              variant={mobileView ? "h6" : "h5"}
              sx={{
                fontFamily: "NotoSansMono-Bold",
                marginBottom: 2,
                width: "100%",
                textAlign: "left",
              }}
            >
              {state.subtitles[0].subtitleName}
            </Typography>
          </Button>
          {state.subtitles
            .filter((e, i, arr) => !e?.isRoot)
            .map((subData) => (
              <Button
                key={subData.subtitleName}
                onClick={() => navigate(subData.subRoute, { state: state })}
                sx={{
                  textTransform: "none",
                  color: "#fff",
                  borderBottomColor: "#fff",
                  borderBottomStyle: "solid",
                  borderBottomWidth: 0.1,
                  marginBottom: 1,
                }}
              >
                <Typography
                  variant={"subtitle2"}
                  sx={{ width: "100%", textAlign: "left" }}
                >
                  {subData.subtitleName}
                </Typography>
              </Button>
            ))}
        </Box>
      ) : null}
    </Box>
  );
};

export default ServiceLayout;
