import React from "react";

// Layouts
import SolutionsContentLayout from "../../../layouts/SolutionsContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const SecurityAwarenessMain = () => {
  const images = [certificate]

  return (
    <SolutionsContentLayout
      header={"Kullanıcı Güvenlik Farkındalığı"}
      images={images}
    >
      Kurum ya da şirketimizde çalışan arkadaşlarımızın, bilgi güvenliği
      konusunda ne kadar hassas olup olmadıklarını anlamak için bazen onları
      test etmemiz kaçınılmaz olmaktadır. Örneğin; XXX faturanız konulu bir
      e-mail dolaştı ortalıkta aylarca, birçok şirketin zarara uğramasına sebep
      olan o maili halen daha yeni bir mail olarak gönderdiğinizde açıldığını
      görüyoruz. Üstelik XXX bir üyelik sahibi olmayan bir kişin yaptığı bir
      hareket bu. Bu ve buna benzeri birçok senaryoyu içerisinde barındıran
      hatta kendi senaryolarınızı oluştura bileceğiniz oltalama yöntemleri
      içeren ve tamamıyla yerli milli olan bir ürünümüz var.
      <br />
      <br />
      Newex önerisi; <b>LUCY</b>.
    </SolutionsContentLayout>
  );
};

export default SecurityAwarenessMain;
