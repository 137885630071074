import React from "react";

// Layouts
import ServiceContentLayout from "../../../layouts/ServiceContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const SecurityTestOne = () => {
  const images = [certificate];

  return (
    <ServiceContentLayout
      header={"ISO/IEC 27701 Kişisel Veri Yönetimi Standardı Danışmanlığı"}
      images={images}
      title={"Uyumluluk Hizmetleri"}
    >
      ISO / IEC 27701: 2019, ISO / IEC 27001'in bir kişisel veri güvenliği için
      geliştirilmiş halidir. Tasarım amacı, bir Kişisel Veri Yönetim Sistemi
      (PIMS) kurmak, uygulamak, sürdürmek ve sürekli olarak iyileştirmek için
      mevcut Bilgi Güvenliği Yönetim Sistemini ek gereksinimlerle
      geliştirmektir.
      <br />
      <br />
      ISO 27001 Bilgi Güvenliği Yönetim Sistemi kişisel veriler de dahil tüm
      bilgi varlıkları ve bu varlıkların transfer yollarını kontrol altında
      tutmayı hedefler. KVKK ile gelen teknik güvenlik gereksinimleri ISO 27001
      Bilgi Güvenliği Yönetim Sistemi kapsamında yapılan çalışmalar ile daha
      rahat standardize edilebilmektedir.
      <br />
      <br />
      ISO 27701 ile tüm kişisel verilerin (Personally Identifiable Information –
      PII) yönetimi için bir standardizasyon sağlanmaktadır. Turkak tarafından
      da akredite edilen standart, kurum ve kuruluşlarda 6698 Kişisel Verilerin
      Korunması Kanunu kapsamında yapılan çalışmalar için ciddi bir olgunluk
      seviyesi göstergesi olacaktır. ISO 27701 Kişisel Veri Yönetim Sistemi
      kurulumu ve denetimi ile firmalar KVKK nezdinde kendi bünyelerinde daha
      önce yapılmış çalışmaların yeterliliğini denetleyebilecek, süreci
      standardize edebileceklerdir.
      <br />
      <br />
      NewEx olarak, KVKK, ISO 27001 ve ISO 27701 projelerinizde tecrübeli
      ekiplerimizle sizlere danışmanlık vermekten memnuniyet duyarız.
    </ServiceContentLayout>
  );
};

export default SecurityTestOne;
