import React from "react";

// Layouts
import ServiceContentLayout from "../../../layouts/ServiceContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const SecurityTestOne = () => {
  const images = [certificate];

  return (
    <ServiceContentLayout
      header={"Güvenlik Tesleri"}
      images={images}
      title={"Güvenlik Testleri"}
    >
      Dünya’da ve Türkiye’de teknoloji son 20 yılda inanılmaz bir hızla
      ilerledi. Otomasyon gerek üretimde gerek operasyonel süreçlerde gerekse
      idari süreçlerde kurum ve kuruluşlara çok hızlı nüfuz etti. Ne yazık ki
      elektronik ortamlara geçişte gösterilen heves bu ortamları korumak için
      gösterilmedi. Kurumların teknolojik yatırımları ile güvenlik yatırımları
      arasında bir makas oluştu ve bu makas kötü niyetli saldırganlar tarafından
      çok kolay sömürülebilmekte.
      <br />
      <br />
      Güvenlik ihlalleri kurumların IT mimarilerinden, güvenlik ürünü
      eksikliğinden, yanlış konumlandırma veya konfigürasyondan, kullanılan
      cihazlarda bilinmeyen açıklıkların olmasından veya kullanıcı hatasından
      gerçekleşebilir. Gerçekleştirilen düzenli sızma testleri kurum ve
      kuruluşların güvenlik altyapılarını test etmeleri ve birçok regülasyona
      uyum için gerekli bir adımdır.
      <br />
      <br />
      NewEx TSE onaylı bir sızma testi firmasıdır ve uzman kadrosuyla yetkin
      güvenlik testleri gerçekleştirmektedir.
    </ServiceContentLayout>
  );
};

export default SecurityTestOne;
