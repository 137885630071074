import React from "react";

// Layouts
import ServiceContentLayout from "../../../layouts/ServiceContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const SecurityTestOne = () => {
  const images = [certificate];

  return (
    <ServiceContentLayout
      header={"Uyumluluk Hizmetleri"}
      images={images}
      title={"Uyumluluk Hizmetleri"}
    >
      NewEx olarak bilgi güvenliği ve siber güvenlik alanındaki 15 yıllık
      tecrübemiz bize regülasyonların kurum ve kuruluşların güvenlik
      altyapılarını iyileştirebilmeleri için en sağlıklı yol olduğunu gösterdi.
      Uluslararası arenada çıkan kanunlar (GDPR, UNCAN…), standartlar (ISO
      27001, ISO 27701, NIST SP800…) ve güvenlik frameworkleri (CIS 20, NIST
      CSF…) kurum ve kuruluşlara ışık tutmakta. NewEx tüm standartlarda
      danışmanlıklar yapmamakta, sadece aşağıda sıraladığımız / uzman olduğu
      alanlarda müşterilerine büyük özveri ile başarı odaklı hizmetler
      sunmaktadır. NewEx olarak tüm kanun ve regülasyonlar kapsamında gerek
      kontrolleri belirler ve dokümante ederken gerekse yatırım tavsiyelerini
      belirlerken temel mottomuz tecrübe ve doğruluktur.
    </ServiceContentLayout>
  );
};

export default SecurityTestOne;
