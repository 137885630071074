import React from "react";

// Layouts
import ServiceContentLayout from "../../../layouts/ServiceContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const SecurityTestOne = () => {
  const images = [
    certificate
  ];

  return (
    <ServiceContentLayout
      header={
        "Cumhurbaşkanlığı DDO Bilgi ve İletişim Güvenliği Rehberi Danışmanlığı"
      }
      images={images}
      title={"Uyumluluk Hizmetleri"}
    >
      Cumhur Başkanlığı Dijital Dönüşüm Ofisi, 10.07.2020 tarihinde Bilgi ve
      İletişim Güvenliği Rehberini yayınladı. Bu rehber siber güvenlik alanında
      Türkiye’de ve uluslararası arenada yayınlanmış kapsam ve derli toplu
      olması açısından örneğine az rastlanır bir kaynak oluşturmakta. Tüm kurum
      ve kuruluşlar için yol gösterici nitelikteki bu rehber özel sektör
      tarafından da takip edilmekte ve yol haritası olarak kullanılmakta.
      <br />
      <br />
      Rehbere uyum sürecin bilgi güvenliği ve siber güvenlik danışmanlığı
      alanında sahip olduğumuz teknik ve yönetsel becerilerimizle sizlere destek
      olmaktan memnuniyet duyarız.
    </ServiceContentLayout>
  );
};

export default SecurityTestOne;
