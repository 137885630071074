import React, { useState } from "react";

// Translation
// import { useTranslation } from "react-i18next";

// Images - Desktop
import SwipeImgOne from "../images/swiper-images/swipe-one-img.jpg";
import SwipeImgTwo from "../images/swiper-images/swipe-two-img.jpg";
import SwipeImgThree from "../images/swiper-images/swipe-three-img.jpg";

// Images - Mobile
import SwipeImgOneMobile from "../images/swiper-images/swipe-one-mobile-img.jpg";
import SwipeImgTwoMobile from "../images/swiper-images/swipe-two-mobile-img.jpg";
import SwipeImgThreeMobile from "../images/swiper-images/swipe-three-mobile-img.jpg";

// Navigation
import { useNavigate } from "react-router-dom";

// Components
import { Box, Button, Divider, Grid, Modal, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typewriter from "typewriter-effect";

// Import Swiper
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import useWindowDimensions from "../hooks/useWindowDimensions";

const HomePage = () => {
  const navigate = useNavigate();
  // const { t } = useTranslation();

  // Modal
  const [open, setOpen] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { width } = useWindowDimensions();

  const mobileView = width < 1080;

  // setInterval(() => {
  //   handleClose();
  // }, 5000);

  const modalStyle = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: mobileView ? "90%" : "60%",
    bgcolor: "black",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    // textAlign: "center",
  };

  const url = "/images/producers/";
  // https://newexsecurity.com

  const someProducersData = [
    // `${url}archsight-logo.png`,
    `${url}authtake-logo.png`,
    `${url}boldon-james-logo.png`,
    `${url}brandefence-logo.png`,
    `${url}data-sunrise-logo.png`,
    `${url}dece-logo.png`,
    `${url}delinea-logo.png`,
  ];

  const sliderData = [
    {
      id: 2,
      slideImg: width <= 375 ? SwipeImgThreeMobile : SwipeImgThree,
      title: "İhtiyaçlarınıza uygun etkili çözümler için yanınızdayız.",
    },
    {
      id: 0,
      slideImg: width <= 375 ? SwipeImgOneMobile : SwipeImgOne,
      title: "Bilgi varlıklarınızın farkında mısınız?",
    },
    {
      id: 1,
      slideImg: width <= 375 ? SwipeImgTwoMobile : SwipeImgTwo,
      title: "Kurumsal farkındalık seviyeniz nedir?",
    },
  ];

  return (
    <Box
      sx={{
        paddingLeft: 1,
        paddingY: mobileView ? 1 : 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
        style={{
          color: "black",
          width: "100%",
          height: mobileView ? "100vw" : "33.25vw",
          marginBottom: 25,
          boxShadow: "0 8px .75rem -1px #000",
        }}
      >
        {sliderData.map((slideItem) => (
          <SwiperSlide
            key={slideItem.id}
            style={{
              backgroundImage: `url(${slideItem.slideImg})`,
              backgroundPosition:
                mobileView && slideItem.id === 0 ? "left" : "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <Box
              sx={{
                width: "85%",
                display: "flex",
                flexDirection: "column",
                alignItems: slideItem.id === 0 ? "baseline" : "center",
                marginLeft: slideItem.id === 0 ? 4 : 0,
              }}
            >
              <Typography
                variant={mobileView ? "h6" : "h4"}
                style={{
                  textAlign: slideItem.id === 0 ? "left" : "center",
                }}
                sx={{
                  color: "white",
                  maxWidth: mobileView ? 240 : 375,
                  textAlign: "left",
                  marginBottom: 2,
                  paddingLeft: slideItem.id === 0 ? 0 : 3,
                }}
              >
                <Typewriter
                  onInit={(typewriter) => {
                    typewriter
                      .pauseFor(slideItem.id !== 2 && 1000)
                      .changeDelay(80)
                      .typeString(slideItem.title)
                      .start();
                  }}
                />
              </Typography>
              <Button
                onClick={() => navigate("contactus")}
                sx={{ borderStyle: "solid", borderWidth: 1, borderRadius: 3 }}
              >
                <Typography variant={mobileView ? "caption" : "button"}>
                  Bize Ulaşın!
                </Typography>
              </Button>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
      <Box sx={{ paddingX: mobileView ? 3 : 15 }}>
        <Box>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
            container
          >
            {someProducersData.map((proImg) => (
              <Grid
                item
                lg={2}
                md={3}
                xs={4.5}
                mb={mobileView && 4}
                key={proImg.slice(-6, -4)}
                sx={{
                  opacity: 0.75,
                  transition: "all .2s ease",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              >
                <img
                  src={proImg}
                  alt=""
                  width={mobileView ? "110" : "135"}
                  height="auto"
                />
              </Grid>
            ))}
          </Grid>
          <Box
            sx={{
              width: "100%",
              textAlign: "center",
              opacity: 0.75,
              transition: "all .2s ease",
              "&:hover": {
                opacity: 1,
              },
              marginY: 6,
              marginTop: mobileView ? 3 : 0,
            }}
          >
            <Typography variant="body1">
              Üreticilerimizin tümünü görmek için
              <Button onClick={() => navigate("producers")}>Tıklayınız</Button>.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: "#141414",
            padding: 3,
            color: "#fff",
            marginBottom: 3,
            borderRadius: 2,
            borderStyle: "dotted",
            borderWidth: 0.1,
            borderColor: "#fff",
          }}
        >
          <Box sx={{ width: "80%" }}>
            <Typography variant="body2">
              20 yılı aşkın sektörel tecrübeye sahip uzmanlar tarafından
              kurulan “NewEx Bilgi Teknolojileri ve Danışmanlık A.Ş.” siber
              güvenlik ve bilgi güvenliği alanlarında ülkemize yeni bir nefes
              olmayı hedeflemiştir. ISO 500 ve Forbes 500’de 100’den fazla
              firmada tamamlamış olduğumuz siber güvenlik ve bilgi güvenliği
              projelerimiz ile elde ettiğimiz, giderek artan bilgi birikimimizi
              müşterilerimizin hizmetine sunuyor olmaktan mutluluk duyuyoruz.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: "#141414",
            padding: 3,
            color: "#fff",
            borderRadius: 2,
            borderStyle: "dotted",
            borderWidth: 0.1,
            borderColor: "#fff",
            display: "flex",
            flexDirection: "column",
            marginBottom: 3,
          }}
        >
          <Box sx={{ width: "80%" }}>
            <Typography variant="body2">
              Bilgi güvenliği ve siber güvenlikle ilgili tüm alanlarda (Siber
              güvenlik altyapı entegrasyon, sıkılaştırma ve izleme hizmetleri,
              bilgi teknolojleri/bilgi güvenliği regülasyonları ve bilgi
              güvenliği/siber güvenlik eğitimleri) hizmet verebilmekteyiz.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: "#141414",
            padding: 3,
            color: "#fff",
            borderRadius: 2,
            borderStyle: "dotted",
            borderWidth: 0.1,
            borderColor: "#fff",
          }}
        >
          <Box sx={{ width: "80%" }}>
            <Typography variant="body2">
              Yatırımlarımızı ve kabiliyetlerimizi müşterilerimizin bilgi
              güvenliği/siber güvenlik yatırımlarında/projelerinde en az maliyet
              ve çaba ile en yüksek performansı alabileceği (güvenli,
              sürdürülebilir, akılcı…) sistemleri kurmak için geliştirmeye devam
              ediyoruz.
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ borderColor: "red" }}
      >
        <Box sx={modalStyle}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h2"
              color="white"
              textAlign="center"
            >
              GEÇMİŞ OLSUN TÜRKİYE
            </Typography>
            <Button onClick={handleClose}>
              <CloseIcon sx={{ color: "white" }} />
            </Button>
          </Box>
          <Typography id="modal-modal-description" sx={{ mt: 2 }} color="white">
            Kahramanmaraş'ta meydana gelen Hatay, Osmaniye, Adıyaman, Gaziantep,
            Şanlıurfa, Diyarbakır, Malatya, Adana, Kilis ve civar illerde
            hissedilen depremde hayatını kaybeden vatandaşlarımıza Allah'tan
            rahmet, yaralı vatandaşlarımıza acil şifalar diliyoruz.
          </Typography>
        </Box>
      </Modal> */}
      <Box
        mt={7}
        p={3}
        bgcolor={"#0e0e0e80"}
        display={"flex"}
        flexDirection={"row"}
      >
        <Box flex={1} sx={{ textAlign: "end" }}>
          <Button onClick={() => navigate("kvkk")}>
            <Typography textTransform={"capitalize"}>
              KVKK Bilgilendirme
            </Typography>
          </Button>
        </Box>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ background: "green", marginX: 3 }}
        />
        <Box flex={1}>
          <Button onClick={() => navigate("eys")}>
            <Typography textTransform={"capitalize"}>EYS Politikası</Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default HomePage;
