import React from "react";
import certificate from "../../../images/certificates/certificate.png";

// Layouts
import SolutionsContentLayout from "../../../layouts/SolutionsContentLayout";

const TrackingOne = () => {
  const images = [certificate]

  return (
    <SolutionsContentLayout header={"Uyumluluk Hizmetleri"} images={images}>
      SIEM sistemlerinin bir sonraki adımı olarak bilinen ve sanal BT çalışanı
      olarak adlandırılan sistemlerdir. SOAR sistemleri senaryo bazlı çalışan
      sistemler olup, sizin oluşturduğunuz sanal senaryolara göre çalışmaktadır.
      <br />
      Örnek; İç networkte aktif durumda çalışan Ahmet adındaki bir kullanıcıya
      ait VPN üzerinden login talebi gelirse, SOAR bu durumu admine mail atarak
      onay ister. Admin den cevap gelmezse SMS atar. Ona da cevap gelmezse, bir
      sonraki en yetkili kişiye döner. Ta ki birisi SOAR a evet bu bilgim
      dahilinde ya da hayır cevabı dönünceye kadar VPN talebine onay verdirmez.
      <br />
      Bu ve buna benzeri milyonlarca senaryoya göre hareket eden ve tüm aktif
      cihaz, server sistemlerine entegre olarak çalışabilen sistemlerdir SOAR
      sistemleri.
      <br />
      <br />
      NewEx önerisi; <b>SIRP</b>.
    </SolutionsContentLayout>
  );
};

export default TrackingOne;
