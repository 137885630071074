import React from "react";

// Layouts
import ServiceContentLayout from "../../../layouts/ServiceContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const SecurityTestOne = () => {
  const images = [certificate];

  return (
    <ServiceContentLayout
      header={"Outsource Firma Güvenlik Yönetimi Danışmanlığı"}
      images={images}
      title={"Güvenlik Danışmanlık Hizmetleri"}
    >
      Eskiden bilgi teknolojileri denince IT profesyonellerinin zayıf akımla
      ilgili her işi yapmaları beklenmekteydi. Sonra yazılım ayrıldı, sonra
      network, sistem, yardım masası, güvenlik ve diğer uzmanlıklar. Bu gün
      güvenlik dahi kendi içinde hem yönetsel (SOME), hem operasyonel (red team,
      blue team…) hem ürün (SIEM, DLP, Firewall…) bazlı farklı uzmanlık
      alanlarına ihtiyaç duymakta. Yapılar büyüdük bu uzmanlıkların hepsine
      sahip personel bulundurmak da zorlaşmakta. Personeli bulmak ayrı, personel
      bağımlılığını yönetmek ayrı, ilgili personeli tatmin edecek iş yükünü
      oluşturmak ayrı problemleri beraberinde getirebilmekte.
      <br />
      <br />
      Biz NewEx olarak firmaların güvenlik süreçlerini outsource personel ve
      takımlarla desteklemekte, ihtiyaç duyulan tüm uzmanlıkları belirlemekte,
      iş planları ve çözümler sunmaktayız.
    </ServiceContentLayout>
  );
};

export default SecurityTestOne;
