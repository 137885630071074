import React from "react";

// Layouts
import SolutionsContentLayout from "../../../layouts/SolutionsContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const NetworkSSeven = () => {
  const images = [certificate]

  return (
    <SolutionsContentLayout header={"Web App Firewall (WAF)"} images={images}>
      Özellikle DMZ olarak adlandırdığımız hem internete hem de şirket
      networkünüze erişimi güvenli tutulan alanlarda yer alan serverlarınıza
      güvenli erişim konusunda, sistemin olmazsa olmazıdır. Günümüzde özellikle
      büyük firmaların / kurumların DMZ’de bulunan serverlarını korumak için,
      bildiğimiz firewall mantığının bu alanda yetersiz kaldığı bilinmektedir.
      HTTP/HTTPS/SOAP/XML-RPC/Web servisleri üzerinde detaylı paket incelemesi
      yapmak içinde yine bir WAF sistemine ihtiyaç duyacaksınız.
      <br />
      <br />
      Newex olarak önerimiz; <b>FortiWAF</b> çözümüdür. Sürekli güncellenen
      database mantığı ile 7/24 tüm trafiğiniz denetim altında tutulmaktadır.
    </SolutionsContentLayout>
  );
};

export default NetworkSSeven;
