import React from "react";

// Layouts
import ServiceContentLayout from "../../../layouts/ServiceContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const SecurityTestOne = () => {
  const images = [certificate];

  return (
    <ServiceContentLayout
      header={"ISO/IEC 27017 Bulut Güvenliği Danışmanlığı"}
      images={images}
      title={"Uyumluluk Hizmetleri"}
    >
      ISO / IEC 27017, bulut hizmeti sağlayıcıları ve kullanıcıları için daha
      güvenli bir bulut tabanlı ortam oluşturmak ve güvenlik sorunları riskini
      azaltmak için geliştirilmiş bir güvenlik standardıdır. ISO 27017 bulut
      hizmetleri için ISO / IEC 27002'ye dayalı bilgi güvenliği kontrollerini
      tanımlayan güvenlik kılavuzudur.
      <br />
      <br />
      Günümüzde uzaktan çalışma ve bulut teknolojilerinin artan kullanım sıklığı
      güvenlik standartlarını da bu alanda evrilmeye itmiştir. Bulut
      teknolojileri üzerinden hizmet veren firmalar ve bulut teknolojilerini
      kullanan firmalar güvenlik sıkılaştırmaları için, altyapılarının olgunluk
      seviyelerinin kontrolü için ISO 27017 standardına uyumu en kısa sürede
      gerçekleştirmelidirler.
      <br />
      <br />
      NewEx olarak teknik ve güvenlik tecrübemizle sizlere bulut güvenliğinizin
      standardizasyonu alanında hizmet vermekten mutluluk duyarız.
    </ServiceContentLayout>
  );
};

export default SecurityTestOne;
