import React from "react";

// Layouts
import ServiceContentLayout from "../../../layouts/ServiceContentLayout";

const SecurityTestOne = () => {
  const images = [];

  return (
    <ServiceContentLayout
      header={"Microsoft Cloud Admin ve Sistem Uzmanlığı Eğitimleri"}
      images={images}
      title={"Eğitimler"}
    >
      - Microsoft Sistem Uzmanlığı Eğitimi (MCSE) Cumartesi - Pazar (135 saat)
      <br />
      - Cisco Network Uzmanlığı (CCNA)
      <br />
      - Administering System Center Configuration Manager (Course 20703-1A)
      <br />
      - Administering Microsoft Exchange Server 2016/2019 (Course 20745-1B)
      <br />
      - Course 20-742B Identity with Windows Server 2016
      <br />
      - 20-741 Networking with Windows Server 2016
      <br />
      - 10982C - Supporting and Troubleshooting Windows 10
      <br />
      - VMware vSphere 7.0 
      <br />
      - Microsoft Azure for IT Professionals
      <br />
      - Teams Son Kullanıcı Eğitimi
      <br />
      - Networking, Windows Server and Cloud Fundamentals
      <br />
      - Microsoft Azure Fundamentals (Az-900)
      <br />
      - Microsoft Azure Administrator (AZ-104)
      <br />
      - Microsoft Azure Architect Technologies (AZ-303)
      <br />
      - Microsoft Azure Architect Design (AZ-304)
      <br />
      - Microsoft 365 Mobility and Security (MS-101)
      <br />
      - Microsoft Identity and Access Administrator (SC-300)
      <br />
      - Microsoft 365 Identity and Services (MS-100)
      <br />- Microsoft Azure Security Technologies (AZ-500)
    </ServiceContentLayout>
  );
};

export default SecurityTestOne;
