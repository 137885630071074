import React from "react";

// Layouts
import SolutionsContentLayout from "../../../layouts/SolutionsContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const DataSThree = () => {
  const images = [certificate]


  return (
    <SolutionsContentLayout header={"Deletions & Erasure"} images={images}>
      Silme ve yok etme olarak anılan bu sistemler, özellikle finans ve bazı
      önemli devlet kademelerinde yoğun olarak kullanılmaktadır. Silinen bir
      disk içerisindeki verinin hiçbir koşulda geri dönüşü olmamalıdır. Bu
      konuda birçok sertifikaya ve referansa da sahip bir ürünümüz
      bulunmaktadır. Türkiye de de birçok güvenlik alanında hizmet veren kamu
      kurum ve kuruluşunda da kullanılmaktadır.
      <br />
      <br />
      NewEx önerisi; White-Canyon.
    </SolutionsContentLayout>
  );
};

export default DataSThree;
