const url = "/images/references/";
// https://newexsecurity.com

const producersData = [
  `${url}acibadem.png`,
  `${url}aktul.png`,
  `${url}sunexpress.png`,
  `${url}fraport-tav.png`,
  `${url}atlasjet.png`,
  `${url}tav-havas.png`,
  `${url}bossa.png`,
  `${url}dyo.png`,
  `${url}egeseramik.png`,
  `${url}flo.png`,
  `${url}a101.png`,
  `${url}zafer-airport.png`,
  `${url}isg-sabiha-gokcen.png`,
  `${url}karaca.png`,
  `${url}koton.png`,
  `${url}limak.png`,
  `${url}multinet.png`,
  `${url}netlog-lojistik.png`,
  `${url}penti.png`,
  `${url}ptt.png`,
  `${url}shgm.png`,
  `${url}tailwind.png`,
  `${url}tav-havas.png`,
  `${url}tirsan.png`,
  `${url}tkdk.png`,
  `${url}turkish-technic.png`,
  `${url}turksat.png`,
  `${url}ulker.png`,
  `${url}akgida.png`,
  `${url}yesilyurt.png`,
  `${url}mgaairlines.png`,
  `${url}yenikoy-kemerkoy-enerji.png`,
];

export default producersData;
