import React from "react";

// Components
import { Box, Typography } from "@mui/material";

// Hooks
import useWindowDimensions from "../hooks/useWindowDimensions";

const ServiceContentLayout = ({ children, header, images, title }) => {
  const { width } = useWindowDimensions();
  const mobileView = width < 760;

  return (
    <Box sx={{ flex: 2.5 }}>
      <Box
        sx={{
          paddingBottom: 1,
          display: "flex",
          flexDirection: "column",
          color: "#1976D2",
          paddingRight: 5,
        }}
      >
        <Typography
          variant={mobileView ? "body2" : "body1"}
          sx={{ fontWeight: "500" }}
        >
          Hizmetler
        </Typography>
        <Typography
          variant={mobileView ? "h5" : "h4"}
          sx={{ fontWeight: "500", marginBottom: 4 }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 2,
            color: "#fff",
            paddingLeft: 5,
          }}
        >
          <Typography
            variant={mobileView ? "h6" : "h5"}
            sx={{ fontFamily: "NotoSansMono-Bold", marginBottom: 3 }}
          >
            {header}
          </Typography>
          <Typography
            variant={mobileView ? "caption" : "body2"}
            sx={{ fontFamily: "NotoSansMono-Light" }}
          >
            {children}
          </Typography>
          <Box paddingTop={5}>
            {images.map((imgItem) => (
              <img
                key={imgItem}
                src={imgItem}
                alt="certificate"
                width={mobileView ? "80" : "100"}
                height="auto"
                style={{ marginRight: 20 }}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ServiceContentLayout;
