const url = "/images/producers/";
// https://newexsecurity.com

const producersData = [
  `${url}archsight-logo.png`,
  `${url}authtake.png`,
  `${url}boldonjames.png`,
  `${url}data-sunrise-logo.png`,
  `${url}dece.png`,
  `${url}deepnetsecurity.png`,
  `${url}delinea.png`,
  `${url}exagrid.png`,
  `${url}falcongaze.png`,
  `${url}forcepoint.png`,
  `${url}fortinet.png`,
  `${url}immuniweb.png`,
  `${url}infoblox.png`,
  `${url}keepnet.png`,
  `${url}kondukto.png`,
  `${url}manageengine.png`,
  `${url}manageengineop.png`,
  `${url}maycyber-technology.png`,
  `${url}zabbix.png`,
  `${url}nessus.png`,
  `${url}grafana.png`,
  `${url}pcichecklist.png`,
  `${url}rapid1.png`,
  `${url}scop-soc-logo.png`,
  `${url}scopvision.png`,
  `${url}pfsense.png`,
  `${url}tenable.png`,
  `${url}trend-micro-logo.png`,
  `${url}whitecanyon.png`,
];

export default producersData;
