import React, { useState } from "react";

// Components
import { Box, Button, Typography } from "@mui/material";
import useWindowDimensions from "../hooks/useWindowDimensions";

const hukukcularLoc =
  "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1506.052892242326!2d29.0923638!3d40.9791644!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac64a88d47e07%3A0x9a65f95efd4bc084!2sS%C3%BCmko%20Sitesi%20M1%20Blok!5e0!3m2!1str!2str!4v1713980831848!5m2!1str!2str";

const ankaraLoc =
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3060.3041169274165!2d32.77109831555212!3d39.91220997942697!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d34882c28b1681%3A0xf073620eea1c13cb!2sMustafa%20Kemal%2C%202139.%20Sk.%20No%3A2%20D%3A16%2C%2006000%20%C3%87ankaya%2FAnkara!5e0!3m2!1sen!2str!4v1664297540574!5m2!1sen!2str";

const ContactUsPage = () => {
  const [location, setLocation] = useState(hukukcularLoc);
  const { width } = useWindowDimensions();

  const mobileView = width < 760;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        paddingX: mobileView ? 4 : 10,
        paddingTop: mobileView ? 5 : 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: mobileView ? "column" : "row",
          alignItems: mobileView ? "center" : "baseline",
          marginBottom: 12,
        }}
      >
        <Box sx={{ borderWidth: 15, borderColor: "#fff" }}>
          <Typography
            sx={{ borderWidth: 15, overflow: "hidden" }}
            textAlign={mobileView ? "center" : "initial"}
            variant={mobileView ? "h4" : "h3"}
            component="h1"
          >
            GÜVENLİK ÇÖZÜMLERİNİZ
            <br />
            İÇİN BİZE ULAŞIN
          </Typography>
          <Box
            sx={{
              margin: 3,
              display: "flex",
              flexDirection: "row",
              justifyContent: mobileView ? "center" : "normal",
            }}
          >
            <Button
              sx={{
                borderRadius: 5,
                borderWidth: 1,
                borderColor: "#fff",
                borderStyle: "solid",
                padding: 1,
                width: "fit-content",
                marginRight: 2,
                color: "#fff",
                textTransform: "none",
              }}
              href="https://www.linkedin.com/company/newexsecurity/"
              target="_blank"
            >
              <Typography variant="body2">LinkedIn</Typography>
            </Button>
            <Button
              sx={{
                borderRadius: 5,
                borderWidth: 1,
                borderColor: "#fff",
                borderStyle: "solid",
                padding: 1,
                width: "fit-content",
                marginRight: 2,
                color: "#fff",
                textTransform: "none",
              }}
              href="https://twitter.com/newexsecurity/"
              target="_blank"
            >
              <Typography variant="body2">Twitter</Typography>
            </Button>
            <Button
              sx={{
                borderRadius: 5,
                borderWidth: 1,
                borderColor: "#fff",
                borderStyle: "solid",
                marginRight: 2,
                padding: 1,
                width: "fit-content",
                color: "#fff",
                textTransform: "none",
              }}
              href="https://instagram.com/newexsecurity/"
              target="_blank"
            >
              <Typography variant="body2">Instagram</Typography>
            </Button>
            <Button
              sx={{
                borderRadius: 5,
                borderWidth: 1,
                borderColor: "#fff",
                borderStyle: "solid",
                padding: 1,
                width: "fit-content",
                color: "#fff",
                textTransform: "none",
              }}
              href="https://support.newexsecurity.com/"
              target="_blank"
            >
              <Typography variant="body2">Destek Portalı</Typography>
            </Button>
          </Box>
        </Box>
        <Box sx={{ paddingLeft: mobileView ? 0 : 10, paddingTop: 5 }}>
          <Box>
            <Typography
              sx={{
                marginTop: 3,
                fontFamily: "NotoSansMono-Bold !important",
                marginBottom: 1,
              }}
              variant="body1"
            >
              [1] Newex Merkez Ofis
            </Typography>
            <Typography variant="body1">
              19 Mayıs Mh. İnönü Cd. Sümer Sk. Sümko Sitesi,
            </Typography>
            <Typography variant="body1"> M6/B Blok, No 5</Typography>
            <Typography variant="body1">Kadıköy/İstanbul, Kozyatağı</Typography>
            <Button>
              <a
                href="https://www.google.com/maps?ll=40.980123,29.095092&z=16&t=m&hl=tr&gl=TR&mapclient=embed&cid=11125572640860520580"
                style={{ color: "#1876D2" }}
                target="_blank"
                rel="noreferrer"
              >
                Haritada Göster
              </a>
            </Button>
          </Box>
          {/* <Box>
            <Typography
              sx={{
                marginTop: 3,
                fontFamily: "NotoSansMono-Bold !important",
                marginBottom: 1,
              }}
              variant="body1"
            >
              [2] Ankara Ofisi
            </Typography>
            <Typography variant="body1">
              Mustafa Kemal Mahallesi 2139.
            </Typography>
            <Typography variant="body1">
              Cadde No: 2 Daire: 16 Çankaya / Ankara
            </Typography>
            <Button onClick={() => setLocation(ankaraLoc)}>
              Haritada Göster
            </Button>
          </Box> */}
          <Box>
            <Typography
              sx={{
                marginTop: 3,
                fontFamily: "NotoSansMono-Bold !important",
                marginBottom: 1,
              }}
              variant="body1"
            >
              İletişim
            </Typography>
            <Box>
              <Typography
                sx={{
                  fontFamily: "NotoSansMono-Bold !important",
                }}
                variant="body2"
              >
                <a href="mailto:info@newexsecurity.com"></a>
              </Typography>
            </Box>
            <Typography variant="body2">
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="mailto:info@newexsecurity.com"
              >
                info@newexsecurity.com
              </a>
            </Typography>
            <Typography variant="body2" color={"white !important"}>
              <a
                href="mailto:support@newexsecurity.com"
                style={{ color: "white", textDecoration: "none" }}
              >
                support@newexsecurity.com
              </a>
            </Typography>
            <Typography variant="body2">
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="tel:+905304801762"
              >
                +90 530 480 1762
              </a>
            </Typography>
            {/* <Typography variant="body1">+90 216 561 76 96</Typography> */}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          marginY: 5,
        }}
      >
        <iframe
          title="Hukukçular Sitesi"
          src={location}
          width="50%"
          height="250"
          style={{ border: 0, filter: "invert(90%)", flex: 1 }}
          // allowfullscreen=""
          loading="lazy"
          // referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </Box>
    </Box>
  );
};

export default ContactUsPage;
