import React from "react";

// Layouts
import SolutionsContentLayout from "../../../layouts/SolutionsContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const NetworkSOne = () => {
  const images = [certificate]

  return (
    <SolutionsContentLayout header={"Firewall"} images={images}>
      Güvenlik duvarları olarak bilinen bu ürünler, firmanıza erişimlerde
      kullanılan güvenlik sistemlerinin başında gelmektedir. Hem iç networkden
      dışarı doğru hem de dışarıdan iç networke doğru yapılan data
      hareketlerinin güvenli ve kontrollu bir şekilde yapılmasını sağlayan ve
      genelde kutu olarak sağlanan çözümlerdir. Bildiğimiz klasik port aç kapa
      olarak bilinen bu ürünler zaman içerisinde kabuk değiştirmiş ve Layer 7
      dediğimiz application seviyelerinde de hizmet etmeye başlamışlardır.
      <br />
      <br />
      Firewallar geleneksel firewall ve NGN firewallar olmak üzere 2 ye
      ayrılırlar.
      <br />
      <br />
      1. Geleneksel firewallar EOL olarak adlandırılan End Of Life dediğimiz
      üretimine son verilmiş sistemlerdir.
      <br />
      <br />
      2. NGN olarak adlandırlan yeni nesil firewallar ise, klasik firewall
      özelliklerinin üzerine, email güvenliği, antivirus, url filter vs... gibi
      bir çok içeriği içinde barındıran sistemlerdir.
      <br />
      <br />
      NGN firewallardan kutu olarak satılan ürünler, her özelliği için cpu
      kaynaklarını ayırarak çalışmaktadır. Bu sayede data trafiginiz ne kadar
      yüksek olursa olsun, sistemin diğer özellikleri bu yoğunluktan
      etkilenmeden sorunsuz çalışmaktadır.
      <br />
      <br />
      NewEx olarak bu alandaki önerimiz FortiGate Firewall'dır.
      <br />
      <br />
      Fortigate firewall; yıllardır güvenlik alanında kendini ispat etmiş,
      özellikle de fiyat/performans tarafında son kullanıcı yanında yer alan bir
      firma olmuştur. Küçük 5-10 kişilik bir firma da olsanız, 10.000 kişilik
      bir firma da olsanız, mutlaka sizin yapınıza uygun bir fortigate modeli
      bulabilirsiniz.
    </SolutionsContentLayout>
  );
};

export default NetworkSOne;
