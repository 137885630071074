import React from "react";

// Layouts
import ServiceContentLayout from "../../../layouts/ServiceContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const SecurityTestOne = () => {
  const images = [certificate];

  return (
    <ServiceContentLayout
      header={"Güvenlik Açıkları ve Zafiyetlerin Kapatılması Danışmanlığı"}
      images={images}
      title={"Güvenlik Danışmanlık Hizmetleri"}
    >
      Kurum ve kuruluşlarda yapılan sızma testleri sonrasında çıkan acil ve
      kritik açıklıklar mümkün olan en kısa sürede kapatılır ve diğer
      açıklıkların kapatılması için genellikle beklenir. Manuel testlerin
      bizlere öğrettiği en önemli hususlardan biri önemsiz gibi görünen birkaç
      açıklığın bir araya geldiğinde firmalar için ciddi sonuçlar
      doğurabildiğidir. Bu açıklıkları belirlemenin tek yolu sızma testi yapmak
      değildir, siber güvenlik uzmanları tarafından gerçekleştirilen diğer bazı
      denetimler de kurumlardaki güvenlik açıklıkları ve zafiyetler için rapor
      oluşturulmasını sağlar. İlgili açıklıkların veya zafiyetlerin kapatılması
      ise başka bir husustur. Bu açıklık bazen teknik bazen de yönetsel
      nedenlerle kapatılamayabilir. Firmaların açıklıkların kapatılmasında da
      profesyonel destek almaları hem alternatif çözümlere erişim açısından hem
      süreç takibi açısından hem de minimum iş gücü ile maksimum verimliliğin
      sağlanması açısından önem arz etmektedir.
      <br />
      <br />
      Biz NewEx olarak, alanında uzman ve sertifikalı danışmanlarımızla IT
      altyapınızda zafiyetlerin belirlenmesi ve raporlanmasından, kapatılması ve
      süreç yönetimine kadar birçok alanda sizlere destek verebilmekteyiz.
    </ServiceContentLayout>
  );
};

export default SecurityTestOne;
