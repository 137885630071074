import React from "react";

// Layouts
import SolutionsContentLayout from "../../../layouts/SolutionsContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const TrackingMain = () => {
  const images = [certificate]

  return (
    <SolutionsContentLayout header={"System Operation Center (SOC)"} images={images}>
      Şirketinizde ya da kurumunuzda çalışan tüm aktif cihaz ve loglarının tek
      bir noktada toplanarak, güvenlik analizine tabii tutulması sonucu oluşan
      risklerden haberdar eden sistemlere SIEM denir. Olay yönetim sistemleri
      olarak da anılmaktadır. Her gün gelişen teknolojiden SIEM sistemleri de
      nasiplerini almaktadır. Artık REGEX / PARSER gibi gelen logları
      ayrıştırmak için yazılan algoritmalar yerlerini yapay zekâ denilen
      öğrenip, öğrendiğini uygulayan sistemlere bırakmaya başlamıştır. Örneğin
      bir firewall dan gönderilen bir logun, hangi marka bir firewall a ait
      olduğunu ya da logun neyle ilgili olduğunu otomatik olarak güncel SIEM
      sistemleri anlayabilir. SIEM sistemlerinizi seçerken en dikkat etmeniz
      gereken konu; EPS denilen 1 saniye deki akan log satır sayısıdır. Şayet
      alacağınız sistem EPS limitlerine göre lisanslanmıyorsa bu durumda
      önerimiz; log gönderecek kaynak sayınızı 3 yıllık ön görüye göre
      hesaplamanız olacaktır.
      <br />
      SIEM sistemlerinizi alırken içlerinde mutlaka UBA ya da UEBA denilen
      kullanıcı ve sistem analizi yapabilen sistemlerden seçmenizi öneririz.
      <br />
      <br />

      <h2>Network Operation Center (NOC)</h2>
      <br />

      Başarılı işletmelerin IT altyapılarını etkin bir şekilde yönetmek ve izlemek için öncelikle
      güçlü bir ağ izleme çözümüne ihtiyaçları vardır. Bu noktada, bizim önerimiz olan Zabbix ve Grafana gibi
      önde gelen yazılımlar, ağ ve sistem yöneticilerine gerekli izleme deneyimini sunarken, birlikte
      kullanıldıklarında ise bu deneyimi çok daha güçlendirirler. NOC (Network Operations Center)
      hizmeti olarak da bilinen bu entegre çözüm, karmaşık ağ altyapılarını ve sunucu sistemlerini etkin bir
      şekilde izlemek ve sorunları önceden tespit ederek çözmek için hayati öneme sahiptir,
      bu sayede şirketler donanım ve ağlarının güvenliğini ve performansını artırırken, operasyonel
      verimliliklerini de önemli ölçüde iyileştirebilirler.

      Eğer siz de Zabbix ve Grafana ile entegre NOC hizmetinin sunduğu avantajlardan yararlanmak istiyorsanız,
      bizimle iletişime geçebilirsiniz. Profesyonel ekibimiz, ihtiyaçlarınıza özel çözümler sunmak
      için size yardımcı olmaktan memnuniyet duyacaktır
      <br />
      NewEx önerisi; <b>SECEON</b>.
    </SolutionsContentLayout>
  );
};

export default TrackingMain;
