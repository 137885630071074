import React from "react";

// Layouts
import ServiceContentLayout from "../../../layouts/ServiceContentLayout";

const SecurityTestOne = () => {
  const images = [];

  return (
    <ServiceContentLayout
      header={
        "Siber Güvenlik ve Bilgi Güvenliği Eğitimleri (Sıfırdan ileri seviyeye kadar)"
      }
      images={images}
      title={"Eğitimler"}
    >
      - Beyaz Şapkalı Hacker Eğitimi (CEH) V11
      <br />
      - Web Uygulama Sızma Testi Eğitimi
      <br />
      - Kurumsal Zafiyet Yönetimi Süreçleri Eğitimi
      <br />
      - Log Dosyalarının Analizi Eğitimi
      <br />
      - Siber Tehdit İstihbaratı Eğitimi(Uygulamalı)
      <br />
      - Siber Tehdit İstihbaratı ve Veriye Dayalı Tehdit Avı Eğitimi
      <br />
      - Bilgisayar Adli Analiz Eğitimi - Derinlemesine Windows Eğitimi
      <br />
      - Windows Sıkılaştırma Eğitimi
      <br />
      - Microsoft Azure for IT Professionals
      <br />
      - Kurumsal Some Eğitimleri
      <br />
      - Zararlı Yazılım Analizi Eğitimi
      <br />
      - DDOS Eğitimi
      <br />
      - Adli Bilişim Temelleri
      <br />
      - Endüstriyel Kontrol Sistemleri İçin Siber Güvenlik Eğitimi
      <br />
      - SOME (Siber Olaylara Müdahale Ekibi) Farkındalık Eğitimi
      <br />
      - Gelişmiş Güvenlik Çözümleri ve Analizleri Eğitimi
      <br />
      - CISSP Sertifikası Hazırlık Eğitimi
      <br />- Güvenli Yazılım Geliştirme Eğitimi
    </ServiceContentLayout>
  );
};

export default SecurityTestOne;
