import React from "react";

// Layouts
import SolutionsContentLayout from "../../../layouts/SolutionsContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const DataSTwo = () => {
  const images = [certificate]

  return (
    <SolutionsContentLayout header={"Data Masking"} images={images}>
      Veri Maskeleme olarak bilinen bu sistemler, KVKK, ISO ve PCI-DSS gibi bazı
      zorunlulukların en başında yer alır.
      <br />
      Amaç tamamıyla bir tablo içerisinde bulunan verilerin yetkisiz kişiler
      tarafından görüntülenmesini engellemektir. Bu konuda sektörde birçok yerli
      ve yabancı ürün bulunmaktadır. Bu ürünler içerisinde seçiminizi yaparken
      dikkat etmeniz gereken ilk husus; çoklu maskeleme seçeneğidir. Örnek;
      Görüntülenecek tablonun aynı an da birçok kolonunu kapata bilir ya da
      okunmayacak şekilde buzlu bir camdan bakar gibi filtreyebilirsiniz.
      <br />
      Bir diğer seçenekte, ilgili tabloyu görüntüleyen kişi ya da kişilerin
      yetkisine göre verileri karışık gösterme seçeneğidir. Örnek olarak; kredi
      kartı numaralarının yazılı olduğu bir tablo düşünün. Bu kart numaralarını
      sistem karıştırarak gösterebilir örnek 4913 ile başlayan bir kart
      numarasını 1943 gibi karıştıracaktır. Bu sayede niyeti kötü olan bir
      kullanıcı, kart numaralarına eriştiğini sanıp ekran görüntüsünü alsa da
      hiçbir işe yaramayacaktır.
      <br />
      <br />
      NewEx olarak önerimiz; <b>IRI DMAAS</b>
    </SolutionsContentLayout>
  );
};

export default DataSTwo;
