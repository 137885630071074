import React from "react";

// Layouts
import ServiceContentLayout from "../../../layouts/ServiceContentLayout";

const SecurityTestOne = () => {
  const images = [];

  return (
    <ServiceContentLayout
      header={"Regülasyon Eğitimleri  (ISO 27001,27701,GDPR -DPO,22301,2000-1)"}
      images={images}
      title={"Eğitimler"}
    >
      - Bilişim Hukuku Eğitimi
      <br />
      - ISO 27001:2013 Bilgi Güvenliği Yönetim Sistemi Baş Denetçi Eğitimi
      <br />
      - ISO 27701:2019 Kişisel Veri Yönetimi Sistemi Baş Denetçi Eğitimi
      <br />
      - EU GDPR Veri Koruma Görevlisi Eğitimi
      <br />
      - ISO 22301:2019 İş Sürekliliği Yönetim Sistemi Baş Denetçi Eğitimi
      <br />
      - ISO 20000-1:2018 BT Hizmet Yönetim Sistemi Baş Denetçi Eğitimi
      <br />
      - CBDDO Bilgi ve İletişim Güvenliği Rehberi Bilgilendirme, Maddesel Analiz
      ve Uygulama Eğitimi
      <br />- ISO 31000 Risk Yönetimi Sistemi Eğitimi
    </ServiceContentLayout>
  );
};

export default SecurityTestOne;
