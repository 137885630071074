import React from "react";

// Layouts
import SolutionsContentLayout from "../../../layouts/SolutionsContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const NetworkSMain = () => {
  const images = [certificate]

  return (
    <SolutionsContentLayout header={"Ağ Güvenliği"} images={images}>
      Hepimizin bildiği üzere, günümüzde ağ güvenliği deyince sadece bir cihaz /
      yazılım alıp takarak ya da yükleyerek bu süreci tamamlayamıyoruz. Kurum ya
      da şirketimizin güvenliğini sağlamak için, bazen 5, bazen 10’dan fazla
      sistemin, yapımıza dahil edilmesi gereken durumlar olabiliyor. Günümüzde
      kullanılan ALL IN ONE (her şey içinde) yapıları da bu durumu iyice
      karıştırmaktadır.
      <br />
      <br />
      Firmamızın 12 yılı aşkın süredir edindiği tecrübelere bakıldığında UTM ya
      da ALL IN ONE denilen ürünlerin birçok noktada sorun oluşturduğu
      gözlenmiştir. Bizler sektörün tecrübeli firmaları olarak; birinci
      hedefimiz siz değerli müşterilerimizin güvenliğini sağlamaktır. Odak
      noktamız da budur. Satış odaklı bir firma hiçbir zaman olmadık,
      olmayacağız.
    </SolutionsContentLayout>
  );
};

export default NetworkSMain;
