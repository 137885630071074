import React from "react";

// Layouts
import ServiceContentLayout from "../../../layouts/ServiceContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const SecurityTestOne = () => {
  const images = [certificate];

  return (
    <ServiceContentLayout
      header={"DoS / DDoS Testi"}
      images={images}
      title={"Güvenlik Testleri"}
    >
      DDoS (Dağıtılmış Hizmet Retti) saldırıları web siteleri gibi bir ağ
      kaynağı için geçerli olan belirli bazı kapasite sınırlarından faydalanır.
      İlgili siteye çok fazla istek göndererek bu sitenin istek işleme
      kapasitesini aşmayı hedefler. Bu tür saldırılar gün geçtikçe çeşitlenmekte
      ve daha sık karşımıza çıkmakta, çok büyük yapıları bile sekteye
      uğratabilmektedir.
      <br />
      <br />
      DDoS testlerini / simülasyonlarını kurumlarla belirlediğimiz metotlar
      çerçevesinde (saldırı türü, bant genişliği, saldırı yapılacak makine
      sayısı…) gerçekleştirmekte ve raporlamaktayız.
    </ServiceContentLayout>
  );
};

export default SecurityTestOne;
