import React from "react";

// Layouts
import SolutionsContentLayout from "../../../layouts/SolutionsContentLayout";

const RiskManagementMain = () => {
  const images = [];

  return (
    <SolutionsContentLayout header={"Risk Yönetimi"} images={images}>
      Şirketinize, kurumunuza bir hacker gözü ile hiç baktınız mı? Ne gibi
      zayıflıklarınız var. Bir hacker olsanız şirketinizde ilk nereye
      saldıracaktınız. Bunları hiç merak ettiniz mi? Ya da bu zayıf
      halkalarınızı bilip, kuvvetlendirmek ister misiniz?
    </SolutionsContentLayout>
  );
};

export default RiskManagementMain;
