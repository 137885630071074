import React from "react";

// Layouts
import ServiceContentLayout from "../../../layouts/ServiceContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const SecurityTestOne = () => {
  const images = [certificate];

  return (
    <ServiceContentLayout
      header={"SCADA Sızma Testi"}
      images={images}
      title={"Güvenlik Testleri"}
    >
      SCADA (Supervisory Control and Data Acquisition – Merkezi Denetleme
      Kontrol ve Veri Toplama) sistemleri enerji sektörü başta olmak üzere
      kritik altyapılarda kullanılmakta. Bu sistemler, çalışma prensipleri
      gereği IT ekipleri tarafından diğer cihazlar kadar kolay yönetilememekte,
      ciddi güvenlik açıklıkları ile karşı karşıya gelmekte ve ISO 27019 gibi
      özelleştirilmiş güvenlik kılavuzları ile sıkılaştırma ihtiyaçları
      desteklenmektedir.
      <br />
      <br />
      NewEx olarak SCADA sistemleri alanında uzmanlaşmış uzman kadromuzla EKS
      testleri gerçekleştirmekteyiz.
    </ServiceContentLayout>
  );
};

export default SecurityTestOne;
