import React from "react";

// Layouts
import ServiceContentLayout from "../../../layouts/ServiceContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const SecurityTestOne = () => {
  const images = [certificate];

  return (
    <ServiceContentLayout
      header={"Güvenlik Danışmanlık Hizmetleri"}
      images={images}
      title={"Güvenlik Danışmanlık Hizmetleri"}
    >
      Siber güvenliğin önemi artık mikro ölçekli firmalar için bile yadsınamaz
      durumdadır. Dünya’da ve Türkiye’de teknoloji son 20 yılda inanılmaz bir
      hızla ilerledi. Otomasyon gerek üretimde gerek operasyonel süreçlerde
      gerekse idari süreçlerde kurum ve kuruluşlara çok hızlı nüfuz etti. Ne
      yazık ki elektronik ortamlara geçişte gösterilen heves bu ortamları
      korumak için gösterilmedi. Bu durum bilgi teknolojileri profesyonelleri
      üzerinde ciddi bir stres yükü oluşturdu. Günümüzde kırılmakta olan eski
      tip güvenlik algısı büyüyen yapılarda BG ekipleri için ciddi profesyonel
      danışmanlık ve yerinde destek ihtiyacı doğurmakta.
      <br />
      <br />
      NewEx olarak 15 yıllık siber güvenlik ve bilgi güvenliği tecrübemizle,
      müşterilerimize ihtiyaç duydukları profesyonel desteği sağlamaktan
      memnuniyet duymaktayız.
    </ServiceContentLayout>
  );
};

export default SecurityTestOne;
