import React from "react";

// Layouts
import ServiceContentLayout from "../../../layouts/ServiceContentLayout";

const SecurityTestOne = () => {
  const images = [];

  return (
    <ServiceContentLayout
      header={"Diğer Eğitimler"}
      images={images}
      title={"Eğitimler"}
    >
      - Temel Linux Eğitimi
      <br />
      - Linux Sistem Uzmanlığı Eğitimi
      <br />
      - Yapay zeka Eğitimi
      <br />
      - Görüntü İşleme
      <br />
      - Görüntü Analizi
      <br />
      - Open Cv Eğitimi
      <br />
      - C
      <br />
      - C++
      <br />
      - Python Eğitimi
      <br />
      - DevOps Eğitimi
      <br />- Veri analizi Eğitimiver
    </ServiceContentLayout>
  );
};

export default SecurityTestOne;
