import React from "react";

// Layouts
import SolutionsContentLayout from "../../../layouts/SolutionsContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const NetworkSEight = () => {
  const images = [certificate]

  return (
    <SolutionsContentLayout
      header={"Network Access Control (NAC)"}
      images={images}
    >
      Şirketinizin iç network yapısına kimler erişmeli ya da erişmemeli ya da
      gerçek zamanlı olarak, kimler hangi IP adresini aldı. Nerelere bağlanmaya
      çalışıyorlar. Ne kadar BAND genişliği harcıyorlar.
      <br />
      <br />
      NewEx olarak önerimiz; FortiNAC’dır. Yukarıda yazdığımız birçok sorunun
      ve daha fazlasının cevabını, marka bağımsız olarak birçok ürünle sorunsuz
      anlaşabilen ve bunu yıllar içerisinde kanıtlamış olan bir üründür. 802.1x
      hiç bu kadar kolay olmamıştı.
    </SolutionsContentLayout>
  );
};

export default NetworkSEight;
