import React from "react";

// Layouts
import ServiceContentLayout from "../../../layouts/ServiceContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const SecurityTestOne = () => {
  const images = [certificate];

  return (
    <ServiceContentLayout
      header={"ISO/IEC 27001 BGYS Danışmanlığı"}
      images={images}
      title={"Uyumluluk Hizmetleri"}
    >
      Bilgi Güvenliği Yönetim Sistemi’nin temel felsefesi tüm bilgi varlıkları
      ve tüm veri transfer yollarını kontrol altında tutmaktır. ISO 27001 BGYS
      standardının bu yaklaşımı içinde bilgi güvenliği barındıran diğer tüm
      kanun ve regülasyonların (KVKK, SPK, Risk Merkezi, YYS…) güvenlik sac
      ayakları için bir platform oluşturmasını sağlar.
      <br />
      <br />
      Bilgi Güvenliği Yönetim Sistemi risk based bir standart olup sadece
      dokümantasyon olarak görülmemeli veya rijit teknik kontrollerden ibaret
      olduğu düşünülmemelidir.
      <br />
      <br />
      Kurum ve kuruluşlarda bilgi güvenliği çalışmaları yapılmadan önce bilgi
      varlıklarının envanterleri çıkarılır ve risk analizleri yapılır. Bu
      çalışmalar sonucunda kurumlarda ne tür bilgi varlıkları var ve üzerlerinde
      ne tür riskler var diye bir analiz yapmış olur ve bir risk seviyesi
      belirlemiş olursunuz. Bilgi varlıkları ve bilgi transfer yolları
      üzerindeki kontrollerde ne kadar ileri gidileceğine bu risk seviyesine
      göre karar verilmelidir. Sonrasında diğer tüm politika, prosedür ve
      talimatlar bu kontroller göz önünde bulundurularak oluşturulmalıdır.
    </ServiceContentLayout>
  );
};

export default SecurityTestOne;
