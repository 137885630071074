import React from "react";

// Layouts
import ServiceContentLayout from "../../../layouts/ServiceContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const SecurityTestOne = () => {
  const images = [certificate];

  return (
    <ServiceContentLayout
      header={"Konfigürasyon Yönetimi Danışmanlığı"}
      images={images}
      title={"Güvenlik Danışmanlık Hizmetleri"}
    >
      Sızma testlerinde zafiyet tarama yazılımları çalıştırılır, peşinden bu
      zafiyetler sömürülerek manuel raporlar oluşturulur. NewEx olarak biz bu
      zafiyetlerin sömürülmesi yanında konfigürasyon eksikliklerinden
      faydalanarak da kurum ve kuruluşlarda yaptığımız testlerde ciddi
      açıklıklar keşfedebilmekteyiz. Konfigürasyon eksikleri / yanlışlıkları
      olması durumunda kurum ve kuruluşların hangi ürünleri kullandıkları veya
      ürünlerin güncellik durumları geçerliliklerini yitirmekte. Bizim sızma
      testlerinde kullandığımız metotlar kötü niyetli girişimlerde de
      uygulanabilmekte.
      <br />
      <br />
      Bu bağlamda kurum ve kuruluşlarda eksik veya yanlış konfigürasyona sahip
      bir güvenlik ürünü bile bir anda ciddi bir zafiyet oluşturabilmekte. NewEx
      olarak alanında uzman sertifikalı danışmanlarımızla, altyapınızda sahip
      olduğunuz bütün cihaz konfigürasyonlarının kontrolünü sağlayarak, yanlış
      ve/veya eksik konfigürasyonları belirledikten sonra tarafınıza raporlayıp,
      onayınız doğrultusunda bu konfigürasyonların en güvenli şekilde
      düzenlenmesini sağlamaktayız.
    </ServiceContentLayout>
  );
};

export default SecurityTestOne;
