import React from "react";

// Layouts
import SolutionsContentLayout from "../../../layouts/SolutionsContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const DataSOne = () => {
  const images = [certificate]

  return (
    <SolutionsContentLayout
      header={"Two Factor Authentication"}
      images={images}
    >
      İki aşamalı erişim olarak anılan bu yöntem, günümüzde internet bankacılığı
      başta olmak üzere birçok kurum ve şirkette kullanılmaktadır. Hepimizin
      hatırlayacağı üzere, çok aşamalı erişim sistemleri ilk çıktıklarında
      sadece OTP (One Time Password) üreten ve anahtarlığı andıran, hatta birçok
      kişinin gerçekten anahtarlığına taktığı kibrit kutusu büyüklüğünde küçük
      kutulardı. Zamanla gelişen ve ucuzlayan bu şifre kullanma yöntemi, parmak
      izi ve daha sonrada mobil telefonlara kadar indi.
      <br />
      Amaç belli; Windows sisteminize login olurken sadece şifrenizi bilmeniz
      artık yetmiyor. Gerçekten o kişi misiniz diyerek cep telefonunuza belirli
      bir süre geçerli olan 6 haneli bir key gönderir. Açık olan şifre
      pencerenize bu sizin için üretilmiş geçici süreli şifreyi yazarak 2.
      Aşamayı da tamamlar ve sisteme giriş yaparsınız.
      <br />
      Neden gerekli?
      <br />
      Çalınan bir laptop ya da cüzdan içerisinde yazan kredi kartı şifreleri ya
      da şirkete erişim bilgileri içeren bir klasörün kopyalanması durumunda
      dahi, cep telefonunuza gelen kodu bilemedikleri için sisteme giriş
      yapamayacaklardır.
      <br />
      <br />
      NewEx önerisi; <b>VASCO</b>
      Bu konuda sektörün ilk çıkan markalarından biri olan VASCO sistemleri,
      günümüzde Türk muhasebe programları dahil entegre edile bilmekte ve birçok
      erişim metodunu desteklemektedir.
    </SolutionsContentLayout>
  );
};

export default DataSOne;
