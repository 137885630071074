import React from "react";

// Layouts
import SolutionsContentLayout from "../../../layouts/SolutionsContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const ConfigurationMMain = () => {
  const images = [certificate]

  return (
    <SolutionsContentLayout header={"Konfigürasyon Yönetimi"} images={images}>
      Özellikle büyük kurum ve şirketlerin çalışan sistem / aktif cihaz
      konfigürasyonlarının yedeklerinin alınması ayrı bir operasyonel yük
      oluşturmaktadır ve bu işlem manuel olarak yapılırken mutlaka arada bir iki
      cihaz atlanmaktadır. ROUTER / SWİTCH, FİREWALL, IPS, WAF vs. daha bir çok
      sisteminizin tüm yedekleri tek bir sistem üzerinde şifreli bir şekilde
      saklanmaktadır. Hem de tamamıyla sizin kontrol dahilinde.
      <br />
      <br />
      Newex önerisi; <b>ANSIBLE</b>.
    </SolutionsContentLayout>
  );
};

export default ConfigurationMMain;
