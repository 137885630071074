import React from "react";

// Layouts
import SolutionsContentLayout from "../../../layouts/SolutionsContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const NetworkSFour = () => {
  const images = [certificate];

  return (
    <SolutionsContentLayout header={"Sandbox"} images={images}>
      Sandbox ürünleri yaptıkları güvenlik analizleri ile tanınmış ürünlerdir.
      Şirketinize gelip giden datalar içerisindeki analizleri yaparlar. Örnek
      vermek gerekirse; şirketinize ulaşan bir .doc dosyasını açmadan önce,
      içeriğini kontrol eder. Bir kod çağıyor mu yoksa sadece word.exe’yi
      çalıştırmak istiyor. “Bunu çalıştırırken cmd.exe’yi de çağırıyor mu?” gibi
      birçok katmanda saniyeler bazında bu kontrolleri gerçekleştiren
      sistemlerdir. İyi bir Sandbox çözümü, kullandığı zararlı içerikleri
      barındıran database ile kolayca belirlenebilir. Burada unutulmaması
      gereken konuların başında; şirketinize gönderilen ve zararlı bir kod
      barındıran dosya / dosyaların ilk çalıştırılması anında, kod ilk olarak
      çalıştığı sistemde bir sandbox çözümünün olup olmadığını kontrol
      etmesidir. Bu tip durumlarda bu yazılımlar kendilerini sisteme farklı
      tanıtabilir.
      <br />
      <br />
      Newex önerisi; VMRAY sistemidir. Kesinlikle tespit edilemez. MacOS işletim
      sistemlerinede sandbox hizmeti verebilen tek markadır. Kullandığınız X
      marka bir End Point yazılımı ile entegre edildiğinde, sandbox hizmetini
      son kullanıcıya kadar indirebilirsiniz.
    </SolutionsContentLayout>
  );
};

export default NetworkSFour;
