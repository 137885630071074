const url = "https://newexsecurity.com/";

const servicesData = [
  {
    id: 0,
    title: "Güvenlik Testleri",
    imgSrc: `${url}img/agguvenligi.png`,
    mainRoute: "/",
    subtitles: [
      {
        subtitleName: "Güvenlik Testleri",
        subRoute: "security-test-main",
        isRoot: true,
      },
      {
        subtitleName: "BDDK Uyumlu Penetrasyon (Sızma) Testi",
        subRoute: "security-test-one",
      },
      {
        subtitleName: "Dış Network Penetrasyon (Sızma) Testi",
        subRoute: "security-test-two",
      },
      {
        subtitleName: "Web Uygulamaları Penetrasyon (Sızma) Testi",
        subRoute: "security-test-three",
      },
      {
        subtitleName: "İç Network Penetrasyon (Sızma) Testi",
        subRoute: "security-test-four",
      },
      {
        subtitleName: "Wifi Penetrasyon (Sızma) Testi",
        subRoute: "security-test-five",
      },
      {
        subtitleName: "Mobil Uygulama Penetrasyon (Sızma) Testi",
        subRoute: "security-test-six",
      },
      {
        subtitleName: "SCADA Penetrasyon (Sızma) Testi",
        subRoute: "security-test-seven",
      },
      {
        subtitleName: "Sosyal Mühendislik Testi",
        subRoute: "security-test-eight",
      },
      {
        subtitleName: "DoS / DDoS Testi",
        subRoute: "security-test-nine",
      },
      {
        subtitleName: "Kaynak Kod Analizi",
        subRoute: "security-test-ten",
      },
    ],
  },
  {
    id: 1,
    title: "Güvenlik Danışmanlık Hizmetleri",
    imgSrc: `${url}img/guvenlikdanismanlik.png`,
    mainRoute: "/",
    subtitles: [
      {
        subtitleName: "Güvenlik Danışmanlık Hizmetleri",
        subRoute: "security-consulting-main",
        isRoot: true,
      },
      {
        subtitleName: "Altyapı Analizi ve Güvenliği Danışmanlığı",
        subRoute: "security-consulting-one",
      },
      {
        subtitleName: "Konfigürasyon Yönetimi Danışmanlığı",
        subRoute: "security-consulting-two",
      },
      {
        subtitleName: "Firewall Kural Yönetimi ve Analizi",
        subRoute: "security-consulting-three",
      },
      {
        subtitleName: "SIEM Yönetimi ve Konfigürasyon Danışmanlığı",
        subRoute: "security-consulting-four",
      },
      {
        subtitleName:
          "Güvenlik Açıkları ve Zafiyetlerin Kapatılması Danışmanlığı",
        subRoute: "security-consulting-five",
      },
      {
        subtitleName: "Outsource Firma Güvenlik Yönetimi Danışmanlığı",
        subRoute: "security-consulting-six",
      },
      {
        subtitleName:
          "Uluslararası Standartlar Kapsamında Güvenlik Seviyesi Belirleme Danışmanlığı",
        subRoute: "security-consulting-seven",
      },
    ],
  },
  {
    id: 2,
    title: "Uyumluluk Hizmetleri",
    imgSrc: `${url}img/uyumluluk.png`,
    mainRoute: "/",
    subtitles: [
      {
        subtitleName: "Uyumluluk Hizmetleri",
        subRoute: "compatibility-main",
        isRoot: true,
      },
      {
        subtitleName: "ISO 27001 BGYS Danışmanlığı",
        subRoute: "compatibility-one",
      },
      {
        subtitleName: "ISO 22301 İş Sürekliliği Danışmanlığı",
        subRoute: "compatibility-two",
      },
      {
        subtitleName: "ISO 37001 Yolsuzlukla Mücadele Yönetim sistemi",
        subRoute: "compatibility-three",
      },
      {
        subtitleName: "ISO/IEC 27017 Bulut Güvenliği Danışmanlığı",
        subRoute: "compatibility-four",
      },
      {
        subtitleName: "ISO27701 Kişisel Veri Yönetimi Standardı Danışmanlığı",
        subRoute: "compatibility-five",
      },
      {
        subtitleName: "KVKK Danışmanlığı",
        subRoute: "compatibility-six",
      },
      {
        subtitleName: "SOME Danışmanlığı",
        subRoute: "compatibility-seven",
      },
      {
        subtitleName:
          "Cumhurbaşkanlığı DDO Bilgi ve İletişim Güvenliği Rehberi Danışmanlığı",
        subRoute: "compatibility-eight",
      },
      {
        subtitleName: "PCI-DSS Danışmanlığı",
        subRoute: "compatibility-nine",
      },
    ],
  },
  {
    id: 3,
    title: "Eğitimler",
    imgSrc: `${url}img/agguvenligi.png`,
    mainRoute: "/",
    subtitles: [
      {
        subtitleName: "Microsoft Cloud Admin ve Sistem Uzmanlığı Eğitimleri",
        subRoute: "trainings-main",
        isRoot: true,
      },
      {
        subtitleName:
          "Regülasyon Eğitimleri  (ISO 27001,27701,GDPR -DPO,22301,2000-1)",
        subRoute: "trainings-one",
      },
      {
        subtitleName:
          "Siber Güvenlik ve Bilgi Güvenliği Eğitimleri (Sıfırdan ileri seviyeye kadar)",
        subRoute: "trainings-two",
      },
      {
        subtitleName: "Diğer Eğitimler",
        subRoute: "trainings-three",
      },
    ],
  },
];

export default servicesData;
