import React from "react";

// Router
import { createBrowserRouter } from "react-router-dom";

// Pages
import HomePage from "../screens/HomePage";
import ServicesPage from "../screens/ServicesPage";
import SolutionsPage from "../screens/Solutions";
import ProducersPage from "../screens/Producers";
import ReferencesPage from "../screens/References";
import ContactUsPage from "../screens/ContactUsPage";

// Services Sub Pages
//    Security Test
import SecurityTestOne from "../components/_servicesPage/securityTests/SecurityTestOne";
import SecurityTestTwo from "../components/_servicesPage/securityTests/SecurityTestTwo";
import SecurityTestThree from "../components/_servicesPage/securityTests/SecurityTestThree";
import SecurityTestFour from "../components/_servicesPage/securityTests/SecurityTestFour";
import SecurityTestFive from "../components/_servicesPage/securityTests/SecurityTestFive";
import SecurityTestSix from "../components/_servicesPage/securityTests/SecurityTestSix";
import SecurityTestSeven from "../components/_servicesPage/securityTests/SecurityTestSeven";
import SecurityTestEight from "../components/_servicesPage/securityTests/SecurityTestEight";
import SecurityTestNine from "../components/_servicesPage/securityTests/SecurityTestNine";
import SecurityTestTen from "../components/_servicesPage/securityTests/SecurityTestTen";
import SecurityTestMain from "../components/_servicesPage/securityTests/SecurityTestMain";

//    Security Consulting
import SecurityConsultingMain from "../components/_servicesPage/securityConsulting/SecurityConsultingMain";
import SecurityConsultingOne from "../components/_servicesPage/securityConsulting/SecurityConsultingOne";
import SecurityConsultingTwo from "../components/_servicesPage/securityConsulting/SecurityConsultingTwo";
import SecurityConsultingThree from "../components/_servicesPage/securityConsulting/SecurityConsultingThree";
import SecurityConsultingFour from "../components/_servicesPage/securityConsulting/SecurityConsultingFour";
import SecurityConsultingFive from "../components/_servicesPage/securityConsulting/SecurityConsultingFive";
import SecurityConsultingSix from "../components/_servicesPage/securityConsulting/SecurityConsultingSix";
import SecurityConsultingSeven from "../components/_servicesPage/securityConsulting/SecurityConsultingSeven";

//    Compatibility
import CompatibilityMain from "../components/_servicesPage/compatibility/CompatibilityMain";
import CompatibilityOne from "../components/_servicesPage/compatibility/CompatibilityOne";
import CompatibilityTwo from "../components/_servicesPage/compatibility/CompatibilityTwo";
import CompatibilityThree from "../components/_servicesPage/compatibility/CompatibilityThree";
import CompatibilityFour from "../components/_servicesPage/compatibility/CompatibilityFour";
import CompatibilityFive from "../components/_servicesPage/compatibility/CompatibilityFive";
import CompatibilitySix from "../components/_servicesPage/compatibility/CompatibilitySix";
import CompatibilitySeven from "../components/_servicesPage/compatibility/CompatibilitySeven";
import CompatibilityEight from "../components/_servicesPage/compatibility/CompatibilityEight";
import CompatibilityNine from "../components/_servicesPage/compatibility/CompatibilityNine";

//    Trainings
import TrainingsMain from "../components/_servicesPage/trainings/TrainingsMain";
import TrainingsOne from "../components/_servicesPage/trainings/TrainingsOne";
import TrainingsTwo from "../components/_servicesPage/trainings/TrainingsTwo";
import TrainingsThree from "../components/_servicesPage/trainings/TrainingsThree";

// Solutions Sub Pages
//    Network Security
import NetworkSMain from "../components/_solutionsPage/networkSecurity/NetworkSMain";
import NetworkSOne from "../components/_solutionsPage/networkSecurity/NetworkSOne";
import NetworkSTwo from "../components/_solutionsPage/networkSecurity/NetworkSTwo";
import NetworkSThree from "../components/_solutionsPage/networkSecurity/NetworkSThree";
import NetworkSFour from "../components/_solutionsPage/networkSecurity/NetworkSFour";
import NetworkSFive from "../components/_solutionsPage/networkSecurity/NetworkSFive";
import NetworkSSix from "../components/_solutionsPage/networkSecurity/NetworkSSix";
import NetworkSSeven from "../components/_solutionsPage/networkSecurity/NetworkSSeven";
import NetworkSEight from "../components/_solutionsPage/networkSecurity/NetworkSEight";
import NetworkSNine from "../components/_solutionsPage/networkSecurity/NetworkSNine";

//    System Security
import SystemSMain from "../components/_solutionsPage/systemSecurity/SystemSMain";

//    Endpoint Security
import EndpointSMain from "../components/_solutionsPage/endpointSecurity/EndpointSMain";
import EndpointSOne from "../components/_solutionsPage/endpointSecurity/EndpointSOne";
import EndpointSTwo from "../components/_solutionsPage/endpointSecurity/EndpointSTwo";

//    Data Security
import DataSMain from "../components/_solutionsPage/dataSecurity/DataSMain";
import DataSOne from "../components/_solutionsPage/dataSecurity/DataSOne";
import DataSTwo from "../components/_solutionsPage/dataSecurity/DataSTwo";
import DataSThree from "../components/_solutionsPage/dataSecurity/DataSThree";

//    User Security Awareness
import SecurityAwarenessMain from "../components/_solutionsPage/securityAwareness/SecurityAwarenessMain";

//    Configuration Managemenet
import ConfigurationMMain from "../components/_solutionsPage/configurationManagement/ConfigurationMMain";

//    Access Management And Remote Working
import AmRwMain from "../components/_solutionsPage/amAndRemoteWorking/AmRwMain";
import AmRwOne from "../components/_solutionsPage/amAndRemoteWorking/AmRwOne";
import AmRwTwo from "../components/_solutionsPage/amAndRemoteWorking/AmRwTwo";
import AmRwThree from "../components/_solutionsPage/amAndRemoteWorking/AmRwThree";

//    Risk Management
import RiskManagementMain from "../components/_solutionsPage/riskManagement/RiskManagementMain";

//    Incident Management And Tracking
import TrackingMain from "../components/_solutionsPage/incidentAndManagement/TrackingMain";
import TrackingOne from "../components/_solutionsPage/incidentAndManagement/TrackingOne";
import TrackingTwo from "../components/_solutionsPage/incidentAndManagement/TrackingTwo";
import TrackingThree from "../components/_solutionsPage/incidentAndManagement/TrackingThree";
import TrackingFour from "../components/_solutionsPage/incidentAndManagement/TrackingFour";

// Layouts
import MainLayout from "../layouts/MainLayout";
import ServiceLayout from "../layouts/ServiceLayout";
import SolutionsLayout from "../layouts/SolutionsLayout";
import NotFound from "../layouts/NotFound";
import KvkkPage from "../screens/KvkkPage";
import EysPage from "../screens/EysPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [{ index: true, element: <HomePage /> }],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "solutions",
        element: <SolutionsLayout />,
        children: [
          {
            index: true,
            element: <SolutionsPage />,
          },
          // Network Security Roots
          {
            path: "network-security-main",
            element: <NetworkSMain />,
          },
          {
            path: "network-security-one",
            element: <NetworkSOne />,
          },
          {
            path: "network-security-two",
            element: <NetworkSTwo />,
          },
          {
            path: "network-security-three",
            element: <NetworkSThree />,
          },
          {
            path: "network-security-four",
            element: <NetworkSFour />,
          },
          {
            path: "network-security-five",
            element: <NetworkSFive />,
          },
          {
            path: "network-security-six",
            element: <NetworkSSix />,
          },
          {
            path: "network-security-seven",
            element: <NetworkSSeven />,
          },
          {
            path: "network-security-eight",
            element: <NetworkSEight />,
          },
          {
            path: "network-security-nine",
            element: <NetworkSNine />,
          },
          // System Security Roots
          {
            path: "system-security-main",
            element: <SystemSMain />,
          },
          // Endpoint Security Roots
          {
            path: "endpoint-security-main",
            element: <EndpointSMain />,
          },
          {
            path: "endpoint-security-one",
            element: <EndpointSOne />,
          },
          {
            path: "endpoint-security-two",
            element: <EndpointSTwo />,
          },
          // Data Security Roots
          {
            path: "data-security-main",
            element: <DataSMain />,
          },
          {
            path: "data-security-one",
            element: <DataSOne />,
          },
          {
            path: "data-security-two",
            element: <DataSTwo />,
          },
          {
            path: "data-security-three",
            element: <DataSThree />,
          },
          // User Security Awareness Roots
          {
            path: "security-awareness-main",
            element: <SecurityAwarenessMain />,
          },
          // Configuration Managemenet Roots
          {
            path: "configuration-management-main",
            element: <ConfigurationMMain />,
          },
          // Access Management And Remote Working Roots
          {
            path: "access-management-main",
            element: <AmRwMain />,
          },
          {
            path: "access-management-one",
            element: <AmRwOne />,
          },
          {
            path: "access-management-two",
            element: <AmRwTwo />,
          },
          {
            path: "access-management-three",
            element: <AmRwThree />,
          },
          // Risk Management Roots
          {
            path: "risk-management-main",
            element: <RiskManagementMain />,
          },
          // Incident Management And Tracking Roots
          {
            path: "tracking-main",
            element: <TrackingMain />,
          },
          {
            path: "tracking-one",
            element: <TrackingOne />,
          },
          {
            path: "tracking-two",
            element: <TrackingTwo />,
          },
          {
            path: "tracking-three",
            element: <TrackingThree />,
          },
          {
            path: "tracking-four",
            element: <TrackingFour />,
          },
        ],
      },
    ],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      // { index: true, path: "/producers", element: <ProducersPage /> },
      {
        path: "services",
        element: <ServiceLayout />,
        children: [
          { index: true, element: <ServicesPage /> },
          // Security Test Roots
          { path: "security-test-one", element: <SecurityTestOne /> },
          { path: "security-test-two", element: <SecurityTestTwo /> },
          { path: "security-test-three", element: <SecurityTestThree /> },
          { path: "security-test-four", element: <SecurityTestFour /> },
          { path: "security-test-five", element: <SecurityTestFive /> },
          { path: "security-test-six", element: <SecurityTestSix /> },
          { path: "security-test-seven", element: <SecurityTestSeven /> },
          { path: "security-test-eight", element: <SecurityTestEight /> },
          { path: "security-test-nine", element: <SecurityTestNine /> },
          { path: "security-test-ten", element: <SecurityTestTen /> },
          { path: "security-test-main", element: <SecurityTestMain /> },
          // Security Consulting Roots
          {
            path: "security-consulting-main",
            element: <SecurityConsultingMain />,
          },
          {
            path: "security-consulting-one",
            element: <SecurityConsultingOne />,
          },
          {
            path: "security-consulting-two",
            element: <SecurityConsultingTwo />,
          },
          {
            path: "security-consulting-three",
            element: <SecurityConsultingThree />,
          },
          {
            path: "security-consulting-four",
            element: <SecurityConsultingFour />,
          },
          {
            path: "security-consulting-five",
            element: <SecurityConsultingFive />,
          },
          {
            path: "security-consulting-six",
            element: <SecurityConsultingSix />,
          },
          {
            path: "security-consulting-seven",
            element: <SecurityConsultingSeven />,
          },
          // Compatibility Roots
          {
            path: "compatibility-main",
            element: <CompatibilityMain />,
          },
          {
            path: "compatibility-one",
            element: <CompatibilityOne />,
          },
          {
            path: "compatibility-two",
            element: <CompatibilityTwo />,
          },
          {
            path: "compatibility-three",
            element: <CompatibilityThree />,
          },
          {
            path: "compatibility-four",
            element: <CompatibilityFour />,
          },
          {
            path: "compatibility-five",
            element: <CompatibilityFive />,
          },
          {
            path: "compatibility-six",
            element: <CompatibilitySix />,
          },
          {
            path: "compatibility-seven",
            element: <CompatibilitySeven />,
          },
          {
            path: "compatibility-eight",
            element: <CompatibilityEight />,
          },
          {
            path: "compatibility-nine",
            element: <CompatibilityNine />,
          },
          // Trainings Roots
          {
            path: "trainings-main",
            element: <TrainingsMain />,
          },
          {
            path: "trainings-one",
            element: <TrainingsOne />,
          },
          {
            path: "trainings-two",
            element: <TrainingsTwo />,
          },
          {
            path: "trainings-three",
            element: <TrainingsThree />,
          },
        ],
      },
    ],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [{ index: true, path: "/producers", element: <ProducersPage /> }],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { index: true, path: "/references", element: <ReferencesPage /> },
    ],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [{ index: true, path: "/contactus", element: <ContactUsPage /> }],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [{ index: true, path: "/kvkk", element: <KvkkPage /> }],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [{ index: true, path: "/eys", element: <EysPage /> }],
  },
  {
    path: "/*",
    element: <NotFound />,
  },
]);

export default router;
