import React from "react";

// Layouts
import ServiceContentLayout from "../../../layouts/ServiceContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const SecurityTestOne = () => {
  const images = [certificate];

  return (
    <ServiceContentLayout
      header={"Web Uygulamaları Sızma Testi"}
      images={images}
      title={"Güvenlik Testleri"}
    >
      Web uygulamaları üzerinde açıklılar konfigürasyon, sunucu veya kod
      kaynaklı olmak üzere birçok nedenle ortaya çıkabilmektedir. Sistemlerin
      gelebilecek saldırılara karşı ne kadar güvende olduğu ve saldırıların
      etkilerinin neler olabileceğinin anlaşılması için sızma testlerinin
      yapılması şarttır.
      <br />
      <br />
      Sızma testleri, NewEx bünyesinde yer alan OSCP, OSWP, CEH, TSE Kıdemli
      Sızma Testi Uzmanı eWPTX- eLearn Sec. Web Application Pentest Extreme,
      OSWE - Offensive Security Web Expert sertifikalarına sahip uzmanlar
      arasından seçilen en az 3 kişilik ekipler tarafından
      gerçekleştirilmektedir.
    </ServiceContentLayout>
  );
};

export default SecurityTestOne;
