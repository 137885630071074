import React from "react";

// Layouts
import ServiceContentLayout from "../../../layouts/ServiceContentLayout";

import certificate from "../../../images/certificates/certificate.png";

const SecurityTestOne = () => {
  const images = [certificate];

  return (
    <ServiceContentLayout
      header={"Altyapı Analizi ve Güvenliği Danışmanlığı"}
      images={images}
      title={"Güvenlik Danışmanlık Hizmetleri"}
    >
      Bilgi Güvenliği / Siber güvenlik alanında kurum ve kuruluşlar çok geniş
      alanlarda savunma yapmak zorunda kalmaktalar. Sistem/network güvenliği,
      güvenli yazılım geliştirme, internet güvenliği, fiziksel güvenlik,
      personel güvenliği, personel farkındalığı, tedarikçi güvenliği gibi
      konuların hepsi bir kenara, günümüzde kullanılan güvenlik
      uygulamalarındaki açıklıkların dahi sömürülebildiği görülmekte. Kurumların
      sistemlerini güven altında tutabilmek için her alanda veya her üründe
      uzman ekipler barındırmaları mümkün olmamakta, olsa bile mevcut ekipler
      için destek ihtiyacı duyulmakta.
      <br />
      <br />
      Bu geniş etki alanı kurum ve kuruluşlar IT altyapıları için doğru
      mimarinin (FW, DNS, VoIP, Sanallaştırma, IDS/IPS…) oluşturulmasının
      önemini artırmaktadır. Sistemlerin birlikte kurgulanması, ürünlerin
      birbirleri ile doğru şekilde konuşmaları veya doğru konumlandırma tüm IT
      ekipleri için hayati önem arz etmekte.
      <br />
      <br />
      NewEx, alanında uzman ve sertifikalı danışmanları ile IT altyapı
      analizinizi gerçekleştirerek bir rapor oluşturmakta ve sizlere rapor
      doğrultusunda güvelik danışmanlığı sunmaktadır.
    </ServiceContentLayout>
  );
};

export default SecurityTestOne;
