import * as React from "react";

// Logo
import logo from "../images/newex-logo-siyah.png";
import yasSymbol from "../images/yas-symbol.png";

// Navigation
import { useNavigate } from "react-router-dom";

// MUI
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import useWindowDimensions from "../hooks/useWindowDimensions";

const drawerWidth = 240;
const navItems = [
  {
    id: 0,
    path: "/",
    pageName: "Anasayfa",
  },
  {
    id: 1,
    path: "services",
    pageName: "Hizmetler",
  },
  {
    id: 2,
    path: "solutions",
    pageName: "Çözümler",
  },
  {
    id: 3,
    path: "producers",
    pageName: "Üreticiler",
  },
  { id: 4, path: "references", pageName: "Referanslar" },
  { id: 5, path: "contactus", pageName: "İletişim" },
];

export default function Navbar(props) {
  const { width } = useWindowDimensions();

  const mobileView = width < 900;
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        textAlign: "center",
        backgroundColor: "#f1f1ee",
        height: "100%",
        color: "#000",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={logo}
          alt="Newex Logo"
          width={"125"}
          height="auto"
          style={{
            // display: mobileView ? "none" : "block",
            marginTop: 16,
            marginBottom: 10,
          }}
          onClick={handleLogoClick}
        />
      </Box>

      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.id} disablePadding>
            <ListItemButton
              onClick={() => navigate(item.path)}
              sx={{
                textAlign: "center",
              }}
            >
              <ListItemText
                sx={{ fontFamily: "NotoSansMono-Bold !important" }}
                primary={item.pageName}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar component="nav" sx={{ backgroundColor: "#f1f1ee" }}>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <IconButton
            color="default"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography
            variant="h5"
            component="div"
            color="#fff"
            sx={{
              maxWidth: "fit-content",
              display: { xs: "none", md: "block" },
            }}
          >
            NewEx
          </Typography> */}
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <img
              src={logo}
              alt="Newex Logo"
              width={mobileView ? "90" : "125"}
              height="auto"
              style={{
                display: mobileView ? "none" : "block",
                marginTop: 5,
                paddingLeft: 10,
                cursor: "pointer",
              }}
              onClick={handleLogoClick}
            />
          </Box>
          <Box sx={{ display: { xs: "none", md: "block" }, paddingRight: 5 }}>
            {navItems.map((item) => (
              <Button
                key={item.id}
                onClick={() => navigate(item.path)}
                sx={{
                  color: "#000",
                  fontSize: 14,
                  textTransform: "none",
                  fontWeight: "900",
                  marginRight: 3,
                  fontFamily: "NotoSansMono-Bold !important",
                }}
              >
                {item.pageName}
              </Button>
            ))}
          </Box>
          {/* <Box color="#000"></Box> */}
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { sm: "block", xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
