import React from "react";

// Layouts
import ServiceContentLayout from "../../../layouts/ServiceContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const SecurityTestOne = () => {
  const images = [certificate];

  return (
    <ServiceContentLayout
      header={"ISO/IEC 22301 İş Sürekliliği Danışmanlığı"}
      images={images}
      title={"Uyumluluk Hizmetleri"}
    >
      İş Sürekliliği Yönetim Sistemi beklenmedik kesintilere karşı hassas kurum
      ve kuruluşlar için doğru iş sürekliliği yapısının kurulması için en
      geçerli kılavuzdur. Sürdürülebilir kapasite ve kabul edilebilir
      kesinti/kayıp için kurumlar gerekli planlamaları yapmış olmalı, doğru
      dokümantasyon, eğitim ve tatbikatlarla bu planları desteklemelidir.
      <br />
      <br />
      İş sürekliliği altyapısının doğru kurgulanabilmesi için iş sürekliliği
      yönetim sisteminin doğru algılanması gerekir. İş sürekliliği günümüzde
      sadece bilgi teknolojilerinin bir sorumluluğu gibi düşünülmekte, ISO 22301
      İş Sürekliliği Yönetim Sistemi de sadece bilgi teknolojileri ile ilgili
      bir standart sanılmakta ve hatta ISO 27001 BGYS Standardının bir parçası
      gibi lanse edilmektedir (ISO 27001 standardında iş sürekliliği sadece
      bilgi güvenliğinin sürekliliği olarak geçer).
      <br />
      <br />
      İş sürekliliğinin sağlanabilmesi için doğru planlama, doğru planlama için
      doğru danışmanlık ve doğru danışmanlık için uzman danışmanlar
      gerekmektedir. Bu bağlamda NewEx olarak sizlere hizmet vermekten
      memnuniyet duyarız.
    </ServiceContentLayout>
  );
};

export default SecurityTestOne;
