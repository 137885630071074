import React from "react";

// Layouts
import ServiceContentLayout from "../../../layouts/ServiceContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const SecurityTestOne = () => {
  const images = [certificate];

  return (
    <ServiceContentLayout
      header={"Dış Network Sızma Testi"}
      images={images}
      title={"Güvenlik Testleri"}
    >
      Kurum ve kuruluşların dış networkleri çok sık saldırıya uğrar ve bu
      saldırılara genellikle hedef alınmadan yapılan saldırılardır. Burada
      bulunan olası zafiyetlerin sömürülmesi kurum imajı açısından da nispeten
      daha önemlidir. NewEx olarak gerçekleştirdiğimiz testler OWASP TOP-10
      önceliklendirilerek gerçekleştirilmektedir.
      <br />
      <br />
      Sızma testleri, NewEx bünyesinde yer alan OSCP, OSWP, CEH, TSE Kıdemli
      Sızma Testi Uzmanı eWPTX- eLearn Sec. Web Application Pentest Extreme,
      OSWE - Offensive Security Web Expert sertifikalarına sahip uzmanlar
      arasından seçilen en az 3 kişilik ekipler tarafından
      gerçekleştirilmektedir.
    </ServiceContentLayout>
  );
};

export default SecurityTestOne;
