import React from "react";

// Layouts
import ServiceContentLayout from "../../../layouts/ServiceContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const SecurityTestOne = () => {
  const images = [certificate];

  return (
    <ServiceContentLayout
      header={"Kaynak Kod Analizi"}
      images={images}
      title={"Güvenlik Testleri"}
    >
      Uluslararası bir çok araştırma raporu başarıya ulaşmış birçok siber
      saldırının uygulama düzeyindeki zafiyetlerden kaynaklı olduğunu
      göstermektedir. Uygulama güvenliği kurumlar için en kritik güvenlik
      katmanlarından birini oluşturmaktadır. Uygulama seviyesindeki güvenlik
      açıklıklarının fark edilmesi ve bu zafiyetlerinin sömürülmesinin diğer
      güvenlik ürünleri ile engellenmesi nispeten daha zordur.
      <br />
      <br />
      NewEx olarak sahip olduğumuz uzmanlarımızla birçok yazılım dilinde (C/C++,
      Java, Objective C…) kod analizlerini gerçekleştirmekte SQL Injection, XSS,
      CRSF gibi açıklıkları manuel ve otomatize toollar yardımı ile tespit
      etmekteyiz.
    </ServiceContentLayout>
  );
};

export default SecurityTestOne;
