import React from "react";

// Layouts
import SolutionsContentLayout from "../../../layouts/SolutionsContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const AmRwOne = () => {
  const images = [certificate];

  return (
    <SolutionsContentLayout header={"PAM"} images={images}>
      Özel haklara sahip erişim sistemleri olarak bilinen bu ürünler sayesinde
      artık uzak bağlantılar çok daha güvenli bir yola girmiştir. <br /> Şirket
      erişimlerinizde sadece web browser kullanmayı hiç düşündünüz mü? Başka
      ilave bir program kullanmadan, VPN ile bağlanmadan. Agent yüklemeden. Ya
      da yapılan bir bağlantının otomatik olarak video kaydının alınmasını ister
      misiniz? Üstelik bağlanacak sisteme / ya da aktif cihaza erişecek kişi
      için kullanıcı adı ve şifresi tanımlamanıza da gerek yok. Bağlantı kuracak
      kişinin erişeceği sisteme ait bilgileri siz PAM sistemine girin. Bağlantı
      kurulduğunda, erişilecek sistemin ikonuna tıklanması yeterli. Kimseyle ne
      bir şifre ne de ip adresi vs. paylaşmanıza gerek yok.
      <br />
      <br />
      NewEx önerisi; XTON.
    </SolutionsContentLayout>
  );
};

export default AmRwOne;
