import React from "react";
import { Box, Button, Typography } from "@mui/material";

import KvkkForm from "../KVKKBasvuruFormu.pdf";

const KvkkPage = () => {
  return (
    <Box padding={5}>
      <Typography variant="h4" mb={5}>
        KVKK Bilgilendirme
      </Typography>
      <Typography pl={5} fontWeight={"800"}>
        6698 Sayılı Kişisel Verilerin Korunması Kanunu Hakkında Bilgilendirme
      </Typography>
      <Typography mb={2}>
        Newex Bilgi Teknolojileri ve Danışmanlık A.Ş. olarak müşterilerimizin
        özel hayatlarının gizliliğine saygı duyarız. Kişisel verilerin
        kullanımında temel hak ve özgürlüklerin korunması için yürürlükte olan
        Kişisel Verilerin Korunması Kanunu* hakkında sizleri bilgilendirmek
        isteriz.
      </Typography>
      <Typography mb={2}>Verilerinizi; </Typography>
      <Typography pl={3}>● Her zaman güvende tutar, </Typography>
      <Typography pl={3}>
        ● İlgili mevzuattan kaynaklanan saklama, açıklama, aktarma, raporlama ve
        diğer ilgili yükümlülükleri güvenle yerine getirir,
      </Typography>
      <Typography pl={3}>
        ● Bilgi Teknolojileri ve İletişim Kurumu ile diğer ilgili
        kurum/kuruluşların bilgi ve belge taleplerini karşılar,
      </Typography>
      <Typography pl={3}>
        ● Ürünlerimizi, hizmetlerimizi ve çözümlerimizi veri güvenliği
        çerçevesinde sunar,
      </Typography>
      <Typography pl={3}>
        ● Yasaların izin verdiği kişiler dışında kimseyle paylaşmayız.
      </Typography>
      <Typography mb={2}>Her zaman bizden; </Typography>
      <Typography pl={3}>
        ● Kişisel verilerinizin kullanılma amacını,{" "}
      </Typography>
      <Typography pl={3}>
        ● Hangi kuruluşlarla ve ne amaçla paylaşıldığı,{" "}
      </Typography>
      <Typography pl={3}>
        ● Eksik veya yanlış kaydedilmiş veya kullanılmış bilgileri revize
        edilmesini,
      </Typography>
      <Typography pl={3}>
        ● Kanunda öngörülen şartların sağlanması halinde bilgilerinizin
        silinmesini veya yok edilmesini talep edebilirsiniz.
      </Typography>
      <Typography>
        Kanun kapsamındaki haklarınızdan yararlanmak için{" "}
        <Button
          href={KvkkForm}
          target="_blank"
          sx={{ textTransform: "capitalize" }}
        >
          KVKK Başvuru Formumuzu
        </Button>{" "}
        kullanarak{" "}
        <span style={{ color: "#1876D2" }}>info@newexsecurity.com</span>’a
        iletebilirsiniz.{" "}
      </Typography>
      <Typography mb={5}>
        Ayrıntılı bilgi için yasayı resmi internet sitesinden
        inceleyebilirsiniz.
      </Typography>
      <Typography>
        * 6698 Sayılı Kişisel Verilerin Korunması Kanunu ("KVKK")
      </Typography>
    </Box>
  );
};

export default KvkkPage;
