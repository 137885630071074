import React from "react";

// Layouts
import SolutionsContentLayout from "../../../layouts/SolutionsContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const TrackingFour = () => {
    const images = [certificate]

  return (
    <SolutionsContentLayout header={"Grafana"} images={images}>
    Grafana ise verilerin görselleştirilmesi ve analiz edilmesi için önde gelen bir
    açık kaynaklı platformdur. Zabbix ile entegre olarak kullanıldığında,
    ağ performans verilerini dinamik ve etkileyici grafiklerle sunarak anlamlı bilgiler
    elde etmeyi sağlar. Grafana'nın esneklik ve özelleştirme yetenekleri, kullanıcıların
    ihtiyaçlarına göre özelleştirilmiş izleme panoları oluşturmasına olanak tanır ve böylece
    ağ performansını hızla değerlendirebilirler.
      <br />
      <br />
      NewEx önerisi; <b>Grafana</b>.
    </SolutionsContentLayout>
  );
};

export default TrackingFour;
