const url = "https://rootcon.com.tr/";

const solutionsData = [
  {
    id: 0,
    title: "Ağ Güvenliği",
    imgSrc: `${url}img/agguvenligi.png`,
    mainRoute: "/",
    subtitles: [
      {
        subtitleName: "Ağ Güvenliği",
        subRoute: "network-security-main",
        isRoot: true,
      },
      {
        subtitleName: "Firewall",
        subRoute: "network-security-one",
      },
      {
        subtitleName: "IDS (Saldırı Tespit Sistemleri)",
        subRoute: "network-security-two",
      },
      {
        subtitleName: "IPS",
        subRoute: "network-security-three",
      },
      {
        subtitleName: "Sandbox",
        subRoute: "network-security-four",
      },
      {
        subtitleName: "DoS ve DdoS",
        subRoute: "network-security-five",
      },
      {
        subtitleName: "Web Security",
        subRoute: "network-security-six",
      },
      {
        subtitleName: "Web App Firewall (WAF)",
        subRoute: "network-security-seven",
      },
      {
        subtitleName: "Network Access Control (NAC)",
        subRoute: "network-security-eight",
      },
      {
        subtitleName: "E-mail Gateway",
        subRoute: "network-security-nine",
      },
    ],
  },
  {
    id: 1,
    title: "Sistem Güvenliği",
    imgSrc: `${url}img/sistemguvenligi.jpg`,
    mainRoute: "/",
    subtitles: [
      {
        subtitleName: "Sistem Güvenliği",
        subRoute: "system-security-main",
        isRoot: true,
      },
    ],
  },
  {
    id: 2,
    title: "Uç Nokta Güvenliği",
    imgSrc: `${url}img/ucnokta.jpg`,
    mainRoute: "/",
    subtitles: [
      {
        subtitleName: "Uç Nokta Güvenliği",
        subRoute: "endpoint-security-main",
        isRoot: true,
      },
      {
        subtitleName: "Anti virüs Solutions",
        subRoute: "endpoint-security-one",
      },
      {
        subtitleName: "Endpoint Detection and Response",
        subRoute: "endpoint-security-two",
      },
    ],
  },
  {
    id: 3,
    title: "Veri Güvenliği",
    imgSrc: `${url}img/veriguvenligi.jpg`,
    mainRoute: "/",
    subtitles: [
      {
        subtitleName: "Data Loss Prevention",
        subRoute: "data-security-main",
        isRoot: true,
      },
      {
        subtitleName: "Two Factor Authentication",
        subRoute: "data-security-one",
      },
      {
        subtitleName: "Data Masking",
        subRoute: "data-security-two",
      },
      {
        subtitleName: "Deletions & Erasure",
        subRoute: "data-security-three",
      },
    ],
  },
  {
    id: 4,
    title: "Kullanıcı Güvenlik Farkındalığı",
    imgSrc: `${url}img/kullanicifarkindaligi.jpg`,
    mainRoute: "/",
    subtitles: [
      {
        subtitleName: "Kullanıcı Güvenlik Farkındalığı",
        subRoute: "security-awareness-main",
        isRoot: true,
      },
    ],
  },
  {
    id: 5,
    title: "Konfigürasyon Yönetimi",
    imgSrc: `${url}img/konfigurasyon.jpg`,
    mainRoute: "/",
    subtitles: [
      {
        subtitleName: "Konfigürasyon Yönetimi",
        subRoute: "configuration-management-main",
        isRoot: true,
      },
    ],
  },
  {
    id: 6,
    title: "Erişim Yönetimi ve Uzaktan Çalışma",
    imgSrc: `${url}img/erisimyonetimi.jpg`,
    mainRoute: "/",
    subtitles: [
      {
        subtitleName: "Password Management",
        subRoute: "access-management-main",
        isRoot: true,
      },
      {
        subtitleName: "PAM",
        subRoute: "access-management-one",
      },
      {
        subtitleName: "Virtual Desktop Infrastructure",
        subRoute: "access-management-two",
      },
      {
        subtitleName: "Database Activity Monitoring",
        subRoute: "access-management-three",
      },
    ],
  },
  {
    id: 7,
    title: "Risk Yönetimi",
    imgSrc: `${url}img/riskyonetimi.jpg`,
    mainRoute: "/",
    subtitles: [
      {
        subtitleName: "Risk Yönetimi",
        subRoute: "risk-management-main",
        isRoot: true,
      },
    ],
  },
  {
    id: 8,
    title: "Olay Yönetimi ve İzleme",
    imgSrc: `${url}img/olayyonetimi.jpg`,
    mainRoute: "/",
    subtitles: [
      {
        subtitleName: "Olay Yönetimi ve İzleme",
        subRoute: "tracking-main",
        isRoot: true,
      },
      {
        subtitleName: "SOAR",
        subRoute: "tracking-one",
      },
      {
        subtitleName: "Monitoring",
        subRoute: "tracking-two",
      },
      {
        subtitleName: "Zabbix",
        subRoute: "tracking-three",
      },
      {
        subtitleName: "Grafana",
        subRoute: "tracking-four",
      },
    ],
  },
];

export default solutionsData;
