import React from "react";

// Layouts
import SolutionsContentLayout from "../../../layouts/SolutionsContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const SystemSMain = () => {
  const images = [certificate]

  return (
    <SolutionsContentLayout
      header={"Sistem Güvenliği • Patch Management"}
      images={images}
    >
      Günümüzde birçok kurum / şirket, yapısına firewall, IPS ya da WAF gibi
      sistemleri dahil ettiğinde tüm önlemleri aldığını düşünmektedir. Ancak bu
      tip sistemler sizleri belli bir noktaya kadar korumaktadır. Bazen bulunan
      açıkları / zafiyetleri kapatmak için IPS, firewall vs. gibi sistemler
      üzerinde yazacağınız kurallar yetersiz kalır, çünkü bulunan zafiyet eksik
      bir güvenlik PATCH (yama) dosyası kaynaklı olabilir. Bu tip durumlarda
      mutlaka sisteminizde çalışan server sistemlerinin üzerinde çalışan işletim
      sistemlerinin özelliklede güvenlik bazlı yama dosyalarının çok sıkı takip
      edilmesi kaçınılmazdır. Şayet yapınızda birçok server bulunuyorsa ya da
      aşırı yoğun çalışan bir departmansanız, bu yama yönetimini otomatiğe
      alırsınız. Ancak Microsoft gibi işletim sistemlerinde yapılan yamalar geri
      alınamıyor. Bu sebeple her çıkan yamayıda yüklemek son derece risklidir.
      İşte tam da bu alanda yardımınıza PATCH yönetimi yapan sistemler
      yetişmektedir.
      <br />
      <br />
      Newex önerisi bu alanda; IVANTI yazılımıdır. Bu yazılım sadece Windows
      değil, Linux, Mac, Unix gibi birçok işletim sistemine ait yama dosyalarını
      yönetmektedir. Çok geride kalmış bir Linux ya da Windows sunucunuzu
      güncellemeden önce, tüm dosyaları otomatik olarak sıraya sokmaktadır.
      Hatta bazı APCT dosyalarını yüklemeden önce, sistemin önce downgrade
      yapılması gerekiyorsa, bunu da kendisi otomatik olarak ayarlamaktadır.
    </SolutionsContentLayout>
  );
};

export default SystemSMain;
