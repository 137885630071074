import React from "react";

// Layouts
import SolutionsContentLayout from "../../../layouts/SolutionsContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const NetworkSNine = () => {
  const images = [certificate]

  return (
    <SolutionsContentLayout header={"Uyumluluk Hizmetleri"} images={images}>
      Günümüzde bir şirkete virüs bulaştırmanın birçok yöntemi bulunmaktadır.
      Bunların en başında e-mail gelmektedir. Sevimli bir hayvan resminden
      tutun, bir manzara resmine dahi tıklamanız başta kendi bilgisayarınız
      olmak üzere bütün yapınızı tehlikeye atabilmektedir. E-mail üzerinden
      şirketinizin başına gelebilecek birkaç kötü senaryoyu sıralamak gerekirse;
      Virüs, Malware ve Spyware önde gelmektedir.
      <br />
      <br />
      NewEx önerisi; <b>FortiMail</b>’dir. DLP sistemlerinizin tamamlayıcı
      unsurlarından biri olan bu sistem sayesinde mailler içerisinde gönderilen
      web linklerinin zararlı içerik barındırıp barındırmadığı ya da gönderenin
      domain yapısının sahte olup olmadığı gibi birçok kontrol saniyeler
      içerisinde yapılarak, sizleri ve şirketinizi koruma altına almaktadır.
    </SolutionsContentLayout>
  );
};

export default NetworkSNine;
