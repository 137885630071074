import React from "react";

// Layouts
import SolutionsContentLayout from "../../../layouts/SolutionsContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const AmRwTwo = () => {
  const images = [certificate];

  return (
    <SolutionsContentLayout
      header={"Virtual Desktop Infrastructure"}
      images={images}
    >
      VDI olarak da anılan bu sistemler, sanal kullanıcı makinaları olarak da
      bilinir. Sadece o kullanıcının kullanacağı programları yükleyip,
      oluşturacağınız bu sistemlere, istenilen yerden bağlanabilir ve çalışıla
      bilinir.
      <br />
      Bu sayede server kaynaklarınızı sonuna kadar verimli bir şekilde
      değerlendirebilirsiniz.
      <br />
      <br />
      NewEx önerisi; <b>CITRIX</b>.
    </SolutionsContentLayout>
  );
};

export default AmRwTwo;
