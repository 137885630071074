import React from "react";

// Layouts
import ServiceContentLayout from "../../../layouts/ServiceContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const SecurityTestOne = () => {
  const images = [certificate];
  return (
    <ServiceContentLayout
      header={"BDDK Uyumlu Sızma Testi"}
      images={images}
      title={"Güvenlik Testleri"}
    >
      Bankacılık ve finans sektörü en ağır siber saldırıya uğrayan sektörlerden
      biridir. Bankacılık Düzenleme ve Denetleme Kurumu (BDDK) tarafından
      ülkemizde kurulu bankalara her yıl “BDDK Kapsamlı Penetrasyon Testi”
      yaptırılması zorunlu tutulmuştur. Bu bağlamda İletişim Altyapısı ve Aktif
      Cihazlar, DNS, Etki Alanı ve Kullanıcı Bilgisayarları, E-posta Servisleri,
      Veritabanı Servisleri, Web Uygulamaları, Mobil Uygulamalar, Kablosuz Ağ
      Sistemleri, Sosyal Mühendislik Testleri, DoS/DDoS Testleri ve Swift
      Sistemleri tarafımızca test edilip raporlanır.
      <br />
      <br />
      Sızma testleri, NewEx bünyesinde yer alan OSCP, OSWP, CEH, TSE Kıdemli
      Sızma Testi Uzmanı eWPTX- eLearn Sec. Web Application Pentest Extreme,
      OSWE - Offensive Security Web Expert sertifikalarına sahip uzmanlar
      arasından seçilen en az 3 kişilik ekipler tarafından
      gerçekleştirilmektedir.
    </ServiceContentLayout>
  );
};

export default SecurityTestOne;
