import React from "react";

// Navigation
import { useNavigate } from "react-router-dom";

// Data
import servicesData from "../constants/datas/servicesData";

// Components
import { Box, Button, Grid, Typography } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

// Hooks
import useWindowDimensions from "../hooks/useWindowDimensions";

const ServicesPage = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const mobileView = width < 760;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        variant={mobileView ? "h4" : "h3"}
        sx={{ padding: 5, paddingTop: 0, fontWeight: "500" }}
        component="h1"
      >
        Hizmetler
      </Typography>
      <Grid
        container
        sx={{
          justifyContent: "center",
          height: "auto",
        }}
      >
        {servicesData.map((serviceItem) => (
          <Grid
            key={serviceItem.id}
            item
            lg={2.5}
            md={5.5}
            xs={10}
            container
            flexDirection="column"
            margin={2}
            sx={{
              borderColor: "#fff",
              transition: "all 250ms ease-in-out",
              boxShadow: "0 4px .75rem -1px #000",
              paddingBottom: 2,
              "&:hover": {
                transform: "translateY(-15px)",
              },
            }}
          >
            <img src={serviceItem.imgSrc} alt="" width="100%" height="auto" />
            <Box
              paddingX={1}
              sx={{
                backgroundColor: "#171717",
                width: "96%",
              }}
            >
              <Button
                sx={{
                  width: "100%",
                  borderBottomStyle: "solid",
                  borderBottomWidth: 0.1,
                  borderBottomColor: "#fff",
                  marginY: 1,
                  paddingBottom: 1,
                }}
                onClick={() =>
                  navigate(serviceItem.subtitles[0]?.subRoute, {
                    state: serviceItem,
                  })
                }
              >
                <Typography
                  variant="h6"
                  color="#fff"
                  sx={{
                    fontFamily: "NotoSansMono-Light !important",
                    textAlign: "left",
                    width: "100%",
                    textTransform: "none",
                  }}
                >
                  {serviceItem.title}
                </Typography>
                <ArrowRightAltIcon sx={{ color: "#fff" }} fontSize="large" />
              </Button>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Button
                  onClick={() =>
                    navigate(serviceItem.subtitles[1]?.subRoute, {
                      state: serviceItem,
                    })
                  }
                  sx={{ width: "100%" }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      textTransform: "none",
                      paddingBottom: 2,
                      borderBottomStyle: "solid",
                      borderBottomWidth: 0.1,
                      textAlign: "left !important",
                      width: "100%",
                    }}
                  >
                    {serviceItem.subtitles[1]?.subtitleName}
                  </Typography>
                </Button>
                <Button
                  onClick={() =>
                    navigate(serviceItem.subtitles[2]?.subRoute, {
                      state: serviceItem,
                    })
                  }
                  sx={{ width: "100%" }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      textTransform: "none",
                      paddingBottom: 2,
                      borderBottomStyle: "solid",
                      borderBottomWidth: 0.1,
                      textAlign: "left !important",
                      width: "100%",
                    }}
                  >
                    {serviceItem.subtitles[2]?.subtitleName}
                  </Typography>
                </Button>
                <Button
                  onClick={() =>
                    navigate(serviceItem.subtitles[3]?.subRoute, {
                      state: serviceItem,
                    })
                  }
                  sx={{ width: "100%" }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      textTransform: "none",
                      paddingBottom: 2,
                      borderBottomStyle: "solid",
                      borderBottomWidth: 0.1,
                      textAlign: "left !important",
                      width: "100%",
                    }}
                  >
                    {serviceItem.subtitles[3]?.subtitleName}
                  </Typography>
                </Button>
                <Button
                  onClick={() =>
                    navigate(serviceItem.subtitles[0]?.subRoute, {
                      state: serviceItem,
                    })
                  }
                  sx={{ width: "100%" }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      textTransform: "none",
                      width: "100%",
                      textAlign: "left !important",
                    }}
                  >
                    Fazlası...
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ServicesPage;
