import React from "react";

// Layouts
import SolutionsContentLayout from "../../../layouts/SolutionsContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const TrackingTwo = () => {
  const images = [certificate]

  return (
    <SolutionsContentLayout header={"Monitoring"} images={images}>
      Sistem izleme olarak bilinen bu sistemler aslında eski network monitoring
      yazılımlarının geliştirilmiş halleridir. Networkte kim hangi ip adresini
      almış, kim ne kadar band genişliği harcıyor. Server disk durumları, sistem
      odası sıcaklığı gibi birçok bilgiyi grafiksel şölene çeviren ayrıca,
      belirleyeceğiniz sınırları aşan durumlar olursa alarm oluşturup mail atan
      sistemlerdir.
      <br />
      <br />
      NewEx önerisi; <b>PRTG</b>.
    </SolutionsContentLayout>
  );
};

export default TrackingTwo;
