import React from "react";

// Layouts
import ServiceContentLayout from "../../../layouts/ServiceContentLayout";

import certificate from "../../../images/certificates/certificate.png";

const SecurityTestOne = () => {
  const images = [certificate];

  return (
    <ServiceContentLayout
      header={"Firewall Kural Yönetimi ve Analizi"}
      images={images}
      title={"Güvenlik Danışmanlık Hizmetleri"}
    >
      Firewall’lar dış tehditlerle firmamız / kurumumuz arasındaki ilk güvenlik
      katmanıdır. Dış ve iç network arasındaki geçiş olduğu için doğru ürünün
      konumlandırılması ve her şeyden önce doğru konfigürasyon çok önemlidir.
      Firewall tek başına yeterli olmamakla birlikte firewall olmaması ya da
      yanlış konfigürasyon siber güvenlik açısından kabul edilmez derecede
      zafiyetler yaratır.
      <br />
      <br />
      NewEx olarak sertifikalı ve alanında uzman danışmanlarla, Firewall
      konfigürasyonlarınızı detaylı olarak inceliyor, eksik veya yanlış
      yapılandırmaları size raporlayarak gerekli çalışmaların tamamlanması için
      destek oluyoruz.
    </ServiceContentLayout>
  );
};

export default SecurityTestOne;
