import React from "react";

// Layouts
import ServiceContentLayout from "../../../layouts/ServiceContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const SecurityTestOne = () => {
  const images = [certificate];

  return (
    <ServiceContentLayout
      header={"İç Network Sızma Testi"}
      images={images}
      title={"Güvenlik Testleri"}
    >
      Kurumlarda güvenlik farklı katmalarda sağlanabilmektedir. Kurum IT
      altyapıları sadece dışarıdan gelecek tehditlere karşı değil, içeriden
      gelecek tehditlere karşı ve/veya kötü niyetli kimselerin içeri sızmaları
      durumunda neler yapabilecekleri ile ilgili olarak da test edilmelidir. Bu
      testler kesinlikle zafiyet taraması ve sonrasında zafiyetlerin sömürülmesi
      gibi basic metotlarla bırakılmamalı mutlaka manuel testler
      gerçekleştirilmelidir. İç networkte yapılan güvenlik testlerinde
      zafiyetlerden ziyade konfigürasyon eksiklikleri veya yanlış konfigüre
      edilmiş sistemler sızma testi uzmanlarına yol gösterecektir.
      <br />
      <br />
      Sızma testleri, NewEx bünyesinde yer alan OSCP, OSWP, CEH, TSE Kıdemli
      Sızma Testi Uzmanı eWPTX- eLearn Sec. Web Application Pentest Extreme,
      OSWE - Offensive Security Web Expert sertifikalarına sahip uzmanlar
      arasından seçilen en az 3 kişilik ekipler tarafından
      gerçekleştirilmektedir.
    </ServiceContentLayout>
  );
};

export default SecurityTestOne;
