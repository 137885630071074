import React from "react";

// Layouts
import ServiceContentLayout from "../../../layouts/ServiceContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const SecurityTestOne = () => {
  const images = [certificate];

  return (
    <ServiceContentLayout
      header={"ISO/IEC 37001 Yolsuzlukla Mücadele Yönetim sistemi"}
      images={images}
      title={"Uyumluluk Hizmetleri"}
    >
      Yolsuzluk ve rüşvet, dünyanın en zorlu sorunlarından birisidir. Bununla
      birlikte, ulusal ve uluslararası düzeyde rüşvetle mücadele çabalarına
      rağmen yolsuzluk, önemli bir sorun olmaya devam etmektedir. Bu sorunun
      farkında olan Uluslararası Standardizasyon Örgütü (“ISO”), kuruluşların
      yolsuzluk ve rüşvet gibi etik dışı davranışlarla mücadele etmelerine
      yardımcı olmak için ISO 37001’i yayınlamıştır.
      <br />
      <br />
      Ekim 2016’da yayınlanan ISO 37001 standardı; kuruluşlara proaktif bir
      Yolsuzlukla Mücadele Yönetim Sistemi uygulama ve sürdürme konusunda
      yardımcı olma amacıyla tasarlanan, yasal uyum odaklı ve etik iş kültürünü
      teşvik eden bir standarttır.
      <br />
      <br />
      ISO 37001, United Nations Convention against Corruption (UNCAC)-Birleşmiş
      Milletler Yolsuzlukla Mücadele Sözleşmesi, European Anti-Corruption
      Conventions-Avrupa Yolsuzlukla Mücadele Sözleşmeleri, USA Foreign Corrupt
      Practices Act-ABD Rüşvetin Önlenmesi ve Yabancı Ülkelerde Yolsuzluk
      Uygulamaları Kanunu ve England Bribery Act-İngiltere Rüşvet Yasası gibi
      uyum yasaları ile aynı protokolü uygulayarak tüm dünyada tanınan ve
      küresel mücadeleyi güçlendiren evrensel bir standarttır.
      <br />
      <br />
      ISO 37001’in Kurumunuza Getirmiş Olduğu Katkılar Nelerdir?
      <br />
      <br />
      ISO 37001:2016, etik ve yolsuzluk karşıtı bir yönetim sisteminin
      kurulması, uygulanması, sürdürülmesi, gözden geçirilmesi ve geliştirilmesi
      için gereksinimleri belirtir ve rehberlik eder. Sistem bağımsız olabilir
      veya genel bir yönetim sistemine entegre edilebilir. ISO 37001:2016, kurum
      veya kuruluşun faaliyetleriyle bağlantılı olarak aşağıdakileri ele
      almaktadır:
      <br />
      <br />
      • Kamu, özel ve kâr amacı gütmeyen sektörlerde yolsuzluk; • Kurum
      tarafından yolsuzluk; • Kurum adına çalışan veya ürün/hizmet alan/satan
      kuruluş personeli tarafından yolsuzluk; • Kurumun iş ortakları tarafından
      kurum adına hareket eden, kurum yararına veya zararına etik dışı tutumlar;
      • Rüşvet; • Kurum faaliyetleri ile ilgili olarak kurum personelinin
      yolsuzluğu; • İş ortaklarıyla ilgili yolsuzluk; • Doğrudan ve dolaylı
      yolsuzluk (Örneğin; Üçüncü bir tarafça veya üçüncü taraf aracılığıyla
      teklif edilen veya kabul edilen etik dışı fayda)
      <br />
      <br />
      Türk Hukukunda Yolsuzluk ve Rüşvete İlişkin Düzenlemeler Nelerdir?
      <br />
      <br />
      Yolsuzluk ve Rüşvete dair mevzuatımızda yer alan bir kısım düzenlemelere
      aşağıda yer verilmiş olup ülkemizin taraf olduğu uluslararası anlaşmalar
      ve Kurumunuzun ticari ilişki içerisinde olduğu uluslararası firmaların
      talebi doğrultusunda gerek yerel hukuka gerekse yabancı ülke hukukuna ve
      uluslararası hukuktan doğan düzenlemelere de ISO 37001 çerçevesinde
      entegrasyonu gerekebilmektedir.
    </ServiceContentLayout>
  );
};

export default SecurityTestOne;
