import React from "react";

// Components
import { Box, Typography } from "@mui/material";

// Hooks
import useWindowDimensions from "../hooks/useWindowDimensions";

const SolutionsContentLayout = ({ children, header, images }) => {
  const { width } = useWindowDimensions();
  const mobileView = width < 760;

  return (
    <Box sx={{ flex: 2.5 }}>
      <Box
        sx={{
          paddingBottom: 1,
          display: "flex",
          flexDirection: "column",
          color: "#1976D2",
          paddingRight: 5,
        }}
      >
        <Typography
          variant={mobileView ? "body2" : "body1"}
          sx={{ fontWeight: "500" }}
        >
          Çözümler
        </Typography>
        <Typography
          variant={mobileView ? "h6" : "h5"}
          sx={{ fontWeight: "500", marginBottom: 4 }}
        >
          {header}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 2,
            color: "#fff",
            paddingLeft: 5,
          }}
        >
          <Typography
            variant={mobileView ? "h6" : "h5"}
            sx={{ fontFamily: "NotoSansMono-Bold", marginBottom: 3 }}
          >
            {header}
          </Typography>
          <Typography
            variant={mobileView ? "caption" : "body2"}
            sx={{ fontFamily: "NotoSansMono-Light" }}
          >
            {children}
          </Typography>
          {images.length > 0 && (
            <Box paddingTop={5}>
              {images.map((imgItem) => (
                <img
                  key={imgItem}
                  src={imgItem}
                  alt="certificate"
                  width={
                    mobileView ? "90" : images.length === 1 ? "200" : "125"
                  }
                  height="auto"
                  style={{ marginRight: 20 }}
                />
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SolutionsContentLayout;
