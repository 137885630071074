import React from "react";

// Layouts
import SolutionsContentLayout from "../../../layouts/SolutionsContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const NetworkSThree = () => {
  const images = [certificate]

  return (
    <SolutionsContentLayout
      header={"IPS (Saldırı Önleme Sistemi)"}
      images={images}
    >
      Saldırı tespiti sonrası anlık müdahale sistemleridir. Önerimiz IPS
      sistemlerinizin ayrı bir kutu olarak alınması yönündedir. IPS
      sistemlerinin IP adresleri olmaz (Management Interface ayrıdır.), sistem
      default olarak signature-base atakları görüp reset atar. Bazı durumlarda
      ise sizin yazdığınız kuralları da işin içine dahil edebilirsiniz.
      <br />
      <br />
      IPS sistemlerinizi konusuna hâkim, bu alanda kendini geliştirmiş olan
      mühendislere kurdurmanız durumunda firewall ihtiyacı kalmadan
      sistemlerinizi koruya bilirsiniz. Özellikle günümüzdeki birçok e-ticaret
      firması, sitelerini sadece IPS sistemleri ile korumaktadır.
    </SolutionsContentLayout>
  );
};

export default NetworkSThree;
