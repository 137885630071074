import React from "react";

// Layouts
import SolutionsContentLayout from "../../../layouts/SolutionsContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const NetworkSFive = () => {
  const images = [certificate]

  return (
    <SolutionsContentLayout header={"DoS ve DdoS"} images={images}>
      Bu saldırı tipi direkt, saldırılan noktayı çökertme/cevap verememe
      durumuna sokmak için icat edilmiştir. Birçok alanda DoS atağı
      düzenlenebilir. En çok bilinen DoS atağı tipi yüklü, büyük PING paketleri
      ile düzenlenen DoS tipidir. Zamanla geliştirilen bu atak tipi, günümüzde
      mail hatta application (layer7) düzeyinde de yapılmaktadır.
      <br />
      <br />
      Dos atakların 2 temel tipi vardır.
      <br />
      1. Tek noktadan düzenlenen ataklar. (Noktadan belli bir noktaya.)
      <br />
      2. Çok noktadan tek noktaya düzenlenen ataklar.
      <br />
      Bu atak tipi en yaygın bilinen ve dağınık birçok noktadan başlatılan atak
      tipleridir. Örnek vermek gerekirse, X bir şirkete yapılacak atağa aynı an
      da birçok ülkedeki zombi haline getirilmiş server / son kullanıcı hatta
      cep telefonları dahil olmaktadır. Bu atak tipini firewall ve IPS gibi
      sistemler durduramaz. Hatta ilk başta çöken sistemler arasında gelirler.
      WAF dediğimiz web application firewall sistemleri ise belli bir ölçüye
      kadar dayanabilmekte ama ciddi ataklarda onlarda aynı kaderi
      paylaşmaktadır. Bu atak tiplerini algılayıp, en kısa sürede devreye
      girerek, müdahale eden sistemler ayrıdır ve DDOS Device'ları olarak
      adlandırılır.
      <br />
      <br />
      NewEx tavsiyesi; NetScout ARBOR'dır. Bu konuda liderliğini defalarca
      ispat etmiş olan bu marka, günümüzde de dünya çapında birçok finans ve
      devlet kurumlarında yoğun olarak çalışmaktadır.
    </SolutionsContentLayout>
  );
};

export default NetworkSFive;
