import React from "react";

// Components
import { Box, Toolbar, Typography } from "@mui/material";
import Navbar from "../components/Navbar";

// Router
import { Outlet } from "react-router-dom";

const MainLayout = ({ children }) => {
  return (
    <Box className="page-container">
      <Navbar />
      <Toolbar />
      <Box className="page-content">
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "92vh",
          }}
        >
          <Typography variant="h1" sx={{ fontWeight: "500" }}>
            404
          </Typography>
          <Typography variant="h2" sx={{ fontWeight: "500" }}>
            Page not found
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MainLayout;
