import React from "react";

// Layouts
import ServiceContentLayout from "../../../layouts/ServiceContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const SecurityTestOne = () => {
  const images = [certificate];

  return (
    <ServiceContentLayout
      header={"Mobil Uygulama Sızma Testi"}
      images={images}
      title={"Güvenlik Testleri"}
    >
      Mobil uygulamalar her alanda kullanımımıza sunulmakta ve firmalar için de
      operasyonel birçok kolaylık sağlamaktalar. Mobil uygulamalar IT ekipleri
      için güvenliği sağlanması gereken bir başka saldırı yüzeyini oluşturmakta.
      Uygulamaların test edilmesi ve olası zafiyetlerin mümkünse uygulama
      yayınlanmadan ortaya çıkarılması çok büyük önem arz etmektedir.
      <br />
      <br />
      NewEx tarafından iOS veya Androis mobil uygulamalar tersine mühendislik,
      kimlik doğrulama, kullanıcı yönetimi, veri doğrulama ve platform zayıflığı
      başta olmak üzere hem manuel hem de otomatik olarak test edilmektedir.
    </ServiceContentLayout>
  );
};

export default SecurityTestOne;
