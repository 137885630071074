import i18next from "i18next";
import { initReactI18next } from "react-i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./locales/en/translation.json";
import translationTR from "./locales/tr/translation.json";

const resources = {
  tr: {
    translation: translationTR,
  },
  en: {
    translation: translationEN,
  },
};

i18next
  .use(initReactI18next)
  // .use(LanguageDetector)
  .init({
    fallbackLng: "tr",

    resources,

    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },
  });
