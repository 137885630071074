import React from "react";

// Layouts
import SolutionsContentLayout from "../../../layouts/SolutionsContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const EndpointSMain = () => {
  const images = [certificate]

  return (
    <SolutionsContentLayout header={"Anti virüs Solutions"} images={images}>
      Anti virüs yazılımları birçok firmanın, kurumun pek önem vermediği bir
      yazılımlar haline gelmiştir. Ancak, bu son derece felaket sonuçlar ile
      karşılaşmamıza sebep olabilecek çok yanlış bir düşüncedir. Anti virüs
      yazılımlarını birçok firma performans problemi sebebiyle yüklemeyi bile
      bırakmış, Windows işletim sistemleri içerisinde ki defender yazılımını
      açarak, bu korumayı sağladıklarını düşünmeye başlamışlardır. Bu son derece
      yanlış bir yaklaşım olmakla birlikte, tüm yapınızı da tehlikeye
      atmaktadır. Bir virüsün yapınızdaki sadece bir host’a ulaşması, sizin tüm
      yaptığınız yatırımı bir anda boşa çıkarabilir.
      <br />
      <br />
      NewEx önerisi; Kaspersky Kaspersky söylenenlerin tam tersine, birçok
      anti virüs yazılımının agent yavaşlığını son kullanıcıya hissettirmeyecek
      kadar aza indirgemiştir. Bu sektördeki en eski ve en çok teste tabii
      tutulan anti virüs yazılımı olma ünvanını halen elinde tutmakta olup, bu
      alanda birçok ödüle de sahiptir.
    </SolutionsContentLayout>
  );
};

export default EndpointSMain;
