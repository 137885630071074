import React from "react";

// Layouts
import SolutionsContentLayout from "../../../layouts/SolutionsContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const NetworkSSix = () => {
  const images = [certificate]

  return (
    <SolutionsContentLayout header={"Uyumluluk Hizmetleri"} images={images}>
      Günümüzde birçok firma yılın belli zamanlarında Pen-Test olarak anılan
      zafiyet testi ya da sızma testi olarak da adlandırılan süreci, yılın belli
      zamanlarında yaptırmaktadır. Özellikle PCI-DSS’e tabii olan firmaların bir
      yıl içerisinde defalarca yaptırması zorunlu olduğu bir test olarak
      bilinir. Teknoloji her geçen gün geliştiği gibi beraberinde birçok
      sıkıntıyı da getirmektedir. Örnek vermek gerekirse; özellikle e-ticaret
      siteleri ya da finans sektöründe yer alan firmaların internet siteleri çok
      yoğun olarak ziyaret edilmekte olup, bunların hepsi masumane ziyaretler
      olmamaktadır. Yapılan bu ziyaretlerin bazıları, internet sitesinde acaba
      bir açık bulabilir miyim? Ya da bir kod hatasından faydalanabilir miyim,
      ziyaretlerini içermektedir. İşte tam da bu alanda sürekli web
      sitelerinizin açıklarını, zafiyetlerini kod bazında analiz ederek sizleri
      bir felaketten önce uyaran sistemler yardımınıza koşmaktadır.
      <br />
      <br />
      Newex önerisi; Immuniweb yazılımıdır. Bu yazılım sitelerinizi sürekli
      kod analizine tabii tutmakta olup, bulduğu öğrendiği bir sorunu size öneri
      sunarak iyileştirmenizi sağlamasıdır. Bunu günün 24 saati sürekli
      yapmaktadır. Pen-Test ya da zafiyet arama yazılımlarını çalıştırmanıza,
      beklemenize gerek kalmadan ya da insan hatası dediğimiz; gözden kaçırma
      olmaksızın, size %0 False-Positive garantisi sunan tek Alman yazılımıdır.
    </SolutionsContentLayout>
  );
};

export default NetworkSSix;
