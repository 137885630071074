import React from "react";

// Layouts
import SolutionsContentLayout from "../../../layouts/SolutionsContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const EndpointSOne = () => {
  const images = [certificate]

  return (
    <SolutionsContentLayout
      header={"Endpoint Detection and Response"}
      images={images}
    >
      Bilişim sektöründeki adı kısaca EDR olarak bilinen, son kullanıcı
      makinasındaki olası sıkıntılı bir dosyanın tespit edilmesi durumunda
      anında müdahale eden sistemler olarak bilinir. Sektörde hemen hemen birçok
      EDR sistemi bulunmaktadır. Burada bir EDR seçimi yaparken dikkat etmeniz
      gereken şey, şayet şirketinizde bir DLP sistemi varsa bununla uyumlu olup
      olmadığını mutlaka sorgulayın.
      <br />
      <br />
      NewEx önerisi; SentinelOne Marka bağımsız olarak birçok DLP ile de sorun
      haberleşmektedir. Sentinelone’nın en iyi özelliklerinden biri aktif bir
      EDR olmasıdır. Yani kullanıcı i şirket dışında olsa dahi, aynı
      şirketteymiş gibi koruma altında tutulmaya devam etmektedir. Bir diğer
      artısı ise offline olarak da çalışmaktadır.
    </SolutionsContentLayout>
  );
};

export default EndpointSOne;
