import React from "react";

// Layouts
import ServiceContentLayout from "../../../layouts/ServiceContentLayout";

import certificate from "../../../images/certificates/certificate.png";

const SecurityTestOne = () => {
  const images = [certificate];

  return (
    <ServiceContentLayout
      header={"Wifi Penetrasyon Sızma Testi"}
      images={images}
      title={"Güvenlik Testleri"}
    >
      Kısmi veya kapsamlı kablosuz ağ kullanımı sağladığı operasyonel
      kolaylıklar nedeni ile (Bring Your Own Device, Guest, Other Mobil
      Devices…) tercih edilmektedir. Kablosuz ağlar etki alanları ve 3.
      Taraflarca kolay erişilebilir olmaları nedeni ile periyodik olarak
      güvenlik testlerine tabi tutulmalıdır.
      <br />
      <br />
      Sızma testleri, NewEx bünyesinde yer alan OSCP, OSWP, CEH, TSE Kıdemli
      Sızma Testi Uzmanı eWPTX- eLearn Sec. Web Application Pentest Extreme,
      OSWE - Offensive Security Web Expert sertifikalarına sahip uzmanlar
      arasından seçilen en az 3 kişilik ekipler tarafından
      gerçekleştirilmektedir.
    </ServiceContentLayout>
  );
};

export default SecurityTestOne;
