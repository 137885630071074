import React from "react";

// Layouts
import SolutionsContentLayout from "../../../layouts/SolutionsContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const DataSMain = () => {
  const images = [certificate]

  return (
    <SolutionsContentLayout header={"Data Loss Prevention"} images={images}>
      DLP olarak da adlandırılan bu ürünü, şirketimizin olmazsa olmazı olarak
      nitelersek çok doğru olacaktır. DLP sistemleri, şirketimizin kim nereyle
      ne paylaştı, mail atılırken ek olarak ne gönderildi, ya da gelen bir mail
      içerisinde ki dosyada yazanlar neler. Bir kredi kartı bilgisi, T.C. No
      gibi bilgiler ya da bir ekran görüntüsü alındı mı, kime gönderildi.
      Okuduğunuz üzere, DLP sistemleri aslında sanal bir göz gibidir. “Kim neyin
      peşinde?” sorusunun yanıtını DLP den başka hiçbir sistem bu kadar detaylı
      olarak veremez. Belirlediğiniz politikalara göre, sistem direkt müdahale
      ederek dokümanlarınız şirket dışına gitmeden önce bloklanır ve bu olayla
      karışan kişi kişileri sizlere alarm oluşturarak bilgi sahibi yapar.
      <br />
      <br />
      Newex olarak önerimiz; SAFETICA Sistem Türkçe ara yüze sahip olup ,
      raporlaması ve forenziği son derece gelişmiştir.
    </SolutionsContentLayout>
  );
};

export default DataSMain;
