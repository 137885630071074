import React from "react";

// Layouts
import ServiceContentLayout from "../../../layouts/ServiceContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const SecurityTestOne = () => {
  const images = [certificate];

  return (
    <ServiceContentLayout
      header={"KVKK Danışmanlığı"}
      images={images}
      title={"Uyumluluk Hizmetleri"}
    >
      6698 sayılı Kişisel Verilerin Korunması Kanunu 2016 yılında yayınlandı her
      geçen gün hem kurum ve kuruluşların hem de bireylerin gündelik hayatına
      daha çok nüfuz etti. Kanun bireylerin kişisel verilerinin yanlış veya alt
      niyetli kullanımının önüne geçmeyi hedeflemekle birlikte, kişisel veri
      işleyen her sektörden, her büyüklükte firma için de iletişim ve veri
      depolama başta olmak üzere birçok kuralı değiştirdi. Kurum ve kuruluşlar
      bir taraftan kişisel veri işleme metotlarını düzenlerken bir taraftan da
      bu verilerin güvenliğini sağlamak zorundalar. Kişisel Verileri Koruma
      Kurumu’nun bildirimleri incelendiğinde idari uygunsuzluklarla birlikte çok
      fazla veri sızıntısı kaynaklı ceza da görülebilmekte.
      https://www.kvkk.gov.tr/Icerik/5406/Kurul-Karar-Ozetleri
      <br />
      <br />
      Kanuna uyum multidisipliner bir yaklaşım gerektirmekte. Bu bağlamda bir
      taraftan tüm kişisel veri işleme yolları ve mevcut veriler kanun nezdinde
      değerlendirmeye alınarak gerekli idari, dokümantasyonel çalışmalar
      tamamlanmalı bir taraftan da gerekli teknik tedbirler uygulanmalıdır. 6698
      sayılı kanuna uyum çalışmaları sürdürülürken dikkat edilmesi gereken en
      önemli konu danışmanlık veren avukatın / hukuk bürosunun sektörel
      kanunlara hâkimiyeti olmalıdır. Hizmet verilen sektöre uygun olarak hukuk
      müşavirlerinin sağlık, finans, havacılık, enerji, dış ticaret gibi sektöre
      özel uzmanlıklarının olması beklenmelidir. NewEx çalışmakta olduğu ulusal
      ve uluslararası hukuk bürolarıyla, bu bağlamda tam kapsamlı bir
      danışmanlık hizmeti sunmaktadır. Bu bağlamda 4 konuda bizlerden hizmet
      alabilirsiniz; - KVKK uyum süreci, hukuki ve teknik danışmanlığı - İş
      Süreçleri Analizi ve Danışmanlığı - Veri Etki Analizi, Veri Güvenliği ve
      Bilgi Teknolojileri - KVKK Denetimi, Mevcut Durum Analizi ve Raporlama
      <br />
      <br />
      Kanuna uyum sürecinin bir sonu olmadığı bilinmeli ve projelerde minimum
      sürede maksimum uyum planlanmalıdır. Kontrollerde risk ve maliyet
      analizleri doğru yapılmalı, gereksiz yatırımlardan kaçınılmalıdır.
    </ServiceContentLayout>
  );
};

export default SecurityTestOne;
