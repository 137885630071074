import React from "react";

// Layouts
import SolutionsContentLayout from "../../../layouts/SolutionsContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const NetworkSTwo = () => {
  const images = [certificate]

  return (
    <SolutionsContentLayout
      header={"IDS (Saldırı Tespit Sistemleri)"}
      images={images}
    >
      Günümüzde bir çok pahalı SIEM ürünü içerisinde yer alan ve saldırıyı ilk
      tanımlayan alan olarak görev yapan sistemlerdir. Düzenlenen saldırının
      tipini de algılayan bu sistemleri dilerseniz SIEM sistemlerinizden
      bağımsız olarak da kurup, kullanabilirsiniz.
      <br />
      <br />
      <b>
        Günümüzde yerli yabancı bir çok güvenlik yazılımının içinde bulunan IDS
        sistemlerini neden ayrı kurma ihtiyacı duyulur?{" "}
      </b>
      <br />
      <br />
      Şirketiniz bünyesinde çalışan SIEM ürünlerinin arayüzleri, markalara göre
      değişkenlik gösterebilir. Bu arayüzler bazen son derece anlaşılır, bazen
      ise alışmış olduğunuz analiz ekranlarından çok uzakta olabilmektedir. İşte
      tam da bu konuda tek başına çalışan IDS sistemleri sizlerin yardımına
      yetişmektedir. Firmamız bu alanda SURICATA olarak anılan yazılımı
      önermektedir.
    </SolutionsContentLayout>
  );
};

export default NetworkSTwo;
