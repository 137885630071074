import React from "react";

// Layouts
import ServiceContentLayout from "../../../layouts/ServiceContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const SecurityTestOne = () => {
  const images = [certificate];

  return (
    <ServiceContentLayout
      header={
        "Uluslararası Standartlar Kapsamında Güvenlik Seviyesi Belirleme Danışmanlığı"
      }
      images={images}
      title={"Güvenlik Danışmanlık Hizmetleri"}
    >
      Kurumların güvenlik altyapıları belirli frameworklere uydurulmalıdır ki
      kapsamda eksiklik olmadığı güvence altına alınabilsin. Burada uygulanması
      gereken frameworkler CIS 20 Controls, NIST CSF, PCI-DSS, ISO27019, ISO
      27017…) ile ilgili firmanızda bir denetim gerçekleştirerek ayrıntılı bir
      karne oluşturabilmekteyiz. Bu frameworkleri için Denetim, GAP Analizi ve
      Uyum Danışmanlıkları’da sunabilmekteyiz.
      <br />
      <br />
      Güvenlik seviyenizin belirlenmesi ve bir karnelerinizin oluşturulması,
      kurumunuzun siber güvenlik açısından zayıf noktalarını bilmeniz
      dolayısıyla yatırımlarınızı yönlendirebilmeniz için en önemli adımlardan
      biridir.
    </ServiceContentLayout>
  );
};

export default SecurityTestOne;
