import React from "react";

// Layouts
import SolutionsContentLayout from "../../../layouts/SolutionsContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const EndpointSTwo = () => {
  const images = [certificate]

  return (
    <SolutionsContentLayout header={"Endpoint Encryption"} images={images}>
      Şirketinize kurumunuza ait PC / laptop vs. gibi sistemleri ister komple
      isterseniz de sadece sabit diskini şifrelemenizi sağlayan sistemlerdir.
      İki tip şifreleme yöntemi vardır. 1. Sistemi komple şifreleme (BIOS
      dahil). Bu özelliği içeren makinalar, power tuşuna basılır basılmaz size
      şifre sorar.
      <br />
      2. Sabit disk olan anılan HARD DİSK’i şifreleme. Bu şifreleme yönetiminde
      sadece disk şifrelenmektedir. Çalınan bir cihazın diski hiçbir şekilde
      erişilemezken, içerisindeki diski söküp atmaları ve başka bir disk
      takmaları durumunda cihaz tekrar hizmet vermeye devam edecektir.
      <br />
      <br />
      NewEx önerisi; <b>Symantec</b>
      <br />
      Bu alanda lider bir konumda olan bu markanın başlıca özellikleri
      içerisinde; şifreleme algoritmasını size seçenek olarak sormasıdır. AES
      128 bit ya da 168 bit şifreleme yapabilirsiniz. Ayrıca yine bu alanda
      yetkin ve başarılı olduğunun göstergesi olarak da FIPS 140-2 sertifikasına
      sahiptir.
    </SolutionsContentLayout>
  );
};

export default EndpointSTwo;
