import React from "react";

// Components
import { Box, Button, Grid, Typography } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

// Hooks
import useWindowDimensions from "../hooks/useWindowDimensions";

import solutionsData from "../constants/datas/solutionsData";

// Router
import { useNavigate } from "react-router-dom";

const Solutions = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const mobileView = width < 540;

  return (
    <Box
      p={mobileView ? 0 : 5}
      pt={0}
      sx={{
        display: mobileView && "flex",
        flexDirection: mobileView && "column",
        alignItems: mobileView && "center",
      }}
    >
      <Typography
        sx={{
          overflow: "hidden",
          textAlign: mobileView && "left",
          width: mobileView && "100%",
        }}
        component="h1"
        mb={mobileView ? 2 : 7.5}
        variant={mobileView ? "h4" : "h3"}
      >
        Çözümler
      </Typography>
      <Grid
        container
        spacing={mobileView ? 0 : 3}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {solutionsData.map((item) => (
          <Grid
            key={item.id}
            item
            lg={3.5}
            md={5.5}
            xs={10}
            container
            flexDirection="column"
            sx={{
              borderColor: "#fff",
              transition: "all 250ms ease-in-out",
              width: "100%",
              minWidth: mobileView && "100%",
              height: "100%",
              padding: "0 !important",
              marginY: 2,
              marginX: 2,
              boxShadow: "0 4px .75rem -1px #000",
              "&:hover": {
                transform: "translateY(-15px)",
              },
            }}
          >
            <img src={item.imgSrc} alt="" width="100%" height="auto" />
            <Box
              sx={{
                backgroundColor: "#171717",
                width: "100%",
                height: "fit-content",
              }}
            >
              <Button
                sx={{
                  width: "100%",
                  borderRadius: 0,
                }}
                onClick={() => navigate(item.subtitles[0].subRoute)}
              >
                <Typography
                  variant="h6"
                  color="#fff"
                  sx={{
                    fontFamily: "NotoSansMono-Light !important",
                    textAlign: "center",
                    width: "100%",
                    textTransform: "none",
                    marginLeft: 1,
                    alignSelf: "center",
                  }}
                >
                  {item.title}
                </Typography>
                <ArrowRightAltIcon
                  sx={{ color: "#fff", marginRight: 1 }}
                  fontSize="large"
                />
              </Button>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Solutions;
