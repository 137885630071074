import React from "react";

// Layouts
import SolutionsContentLayout from "../../../layouts/SolutionsContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const TrackingThree = () => {
    const images = [certificate]

  return (
    <SolutionsContentLayout header={"Zabbix"} images={images}>
       Zabbix, güçlü ağ izleme ve yönetim çözümleri sunan açık kaynaklı bir platformdur.
       Geniş ölçekli ağlar üzerinde gerçek zamanlı izleme ve performans analizi yapabilme
       yeteneğiyle öne çıkar. Sunucular, ağ cihazları, bulut hizmetleri ve uygulamalar gibi
       birçok farklı bileşeni izleyebilir ve kullanıcılarına kapsamlı bir görünüm sunar,
       esnek alarm ve bildirim sistemleri, ağ ve sistem yöneticilerine anında müdahale
       edebilmeleri için gereken bilgiyi sağlar.
      <br />
      <br />
      NewEx önerisi; <b>Zabbix</b>.
    </SolutionsContentLayout>
  );
};

export default TrackingThree;
