import React from "react";

// Layouts
import ServiceContentLayout from "../../../layouts/ServiceContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const SecurityTestOne = () => {
  const images = [certificate];

  return (
    <ServiceContentLayout
      header={"PCI-DSS Danışmanlığı"}
      images={images}
      title={"Uyumluluk Hizmetleri"}
    >
      PCI (Payment Card Industry) aralarında American Express, MasterCard
      Worlwide ve Visa gibi üyelerin bulunduğu bir konsey tarafından
      yayınlanmıştır ve kredi kartının işlenmesi, iletilmesi, saklanması gibi
      aşamalarda uyulması gereken güvenlik kurallarını belirlemektedir. PCI-DSS
      (Payment Card Industry-Data Security Standard) ödeme sistemleri ile
      ilişkili süreçlerde uyulması gereken fiziksel ve mantıksal gereksinimleri
      tanımlamaktadır. Bilgi güvenliği ve siber güvenlik alanında uzman
      kadrolarımızla sizlere PCI-DSS çalışmaları kapsamında da hizmet vermekten
      memnuniyet duyarız.
    </ServiceContentLayout>
  );
};

export default SecurityTestOne;
