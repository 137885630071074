import React from "react";

// Layouts
import ServiceContentLayout from "../../../layouts/ServiceContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const SecurityTestOne = () => {
  const images = [certificate];
  return (
    <ServiceContentLayout
      header={"SOME Danışmanlığı"}
      images={images}
      title={"Uyumluluk Hizmetleri"}
    >
      Uluslararası alanda tüm ülkeler, siber güvenliği sağlamak için çalışmalar
      yürütmekteler. Siber güvenlik alanında birçok ulusal ve uluslararası kanun
      ve regülasyon yayınlandı ve yayınlanmaya devam etmekte. Özel komiteler
      kurulmakta ve güvenlik için frameworkleri oluşturulmakta. Ülkemizde de bu
      çalışmalar siber güvenlik çalıştayları ile başladı, siber güvenlik eylem
      planları yayınlandı. Ulusal Siber Olaylara Müdahale (USOM) Merkezi’nin
      kurulmasının peşinden kritik altyapılar ve devlet kurumları başta olmak
      üzere SOME ekiplerinin oluşturulması, yayınlanan sektörel SOME’lere uyumun
      sağlanması istenmiştir.
      <br />
      <br />
      SOME ekiplerinin nasıl kurulacağı, organizasyon, teknik altyapı ve eğitim
      gereksinimleri gibi birçok ayrıntı sektörel SOME’ler (EPDK, SHGM…)
      tarafından oluşturulmuştur. Günümüzde sadece kritik altyapılar ve devlet
      kurumları değil bilgi güvenliğine önem veren tüm kuruluşlarda SOME
      çalışmaları yürütülmektedir.
      <br />
      <br />
      SOME çalışmalarınızda ekiplerin oluşturulması (organizasyon, yetkinlik,
      eğitim…), bilgi teknolojileri mimarisinin SOME’ye uygun hale getirilmesi,
      SOME uygunluk oranınızın değerlendirilmesi ve eğitim gibi birçok alanda
      NewEx olarak müşterilerimize hizmet sunmaktayız.
    </ServiceContentLayout>
  );
};

export default SecurityTestOne;
