import React from "react";

// Layouts
import SolutionsContentLayout from "../../../layouts/SolutionsContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const AmRwMain = () => {
  const images = [certificate]

  return (
    <SolutionsContentLayout header={"Password Management"} images={images}>
      Şifrelerinizi AES 168 ya da 256 bit olarak saklamak ister misiniz? Ya da
      erişimleriniz sırasında şifrelerinizin otomatik olarak ilgili hanelere
      girilmesini? Böyle bir şeye ne gerek var demeyin. Sisteminizin kapacağı
      küçük bir key-logger ile tüm şifrelerinizi dinleye bilirler.
      <br />
      <br />
      NewEx önerisi; <b>DASHLANE</b>.
    </SolutionsContentLayout>
  );
};

export default AmRwMain;
