import React from "react";

// Layouts
import SolutionsContentLayout from "../../../layouts/SolutionsContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const AmRwThree = () => {
  const images = [certificate];

  return (
    <SolutionsContentLayout
      header={"Database Activity Monitoring"}
      images={images}
    >
      DAM olarak da bilinen bu sistemler, birçok marka altında hizmet
      vermektedir.
      <br />
      Bir DAM seçimi yaparken en önemli özellik; marka bağımsız her DB yapısına
      uyumlu olmasıdır.
      <br />
      DB üzerinde ki disklerin ne kadarı boş ya da dolu, en çok hangi tablo
      kullanılıyor, tablo sağlığı nasıl, erişim ve cevap dönme süreleri nasıl,
      kimler hangi tablolara bağlanıyor, ne yapıyor? Bu sorularınızın
      cevaplarını verecek olan sistemlere DAM denir.
      <br />
      <br />
      NewEx öneriyor; ManageEngine.
    </SolutionsContentLayout>
  );
};

export default AmRwThree;
