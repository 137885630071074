import React from "react";

// Layouts
import ServiceContentLayout from "../../../layouts/ServiceContentLayout";
import certificate from "../../../images/certificates/certificate.png";

const SecurityTestOne = () => {
  const images = [certificate];

  return (
    <ServiceContentLayout
      header={"SIEM Yönetimi ve Konfigürasyon Danışmanlığı"}
      images={images}
      title={"Güvenlik Danışmanlık Hizmetleri"}
    >
      SIEM güvenlik yapılarının merkezinde yer alır. Log verebilen tüm
      cihazlardan (switch, firewall, IoT, PC, Server…) topladığı logları korele
      eder ve korelasyonlar doğrultusunda alarmlar oluşturur. SIEM çözümleri ile
      ilgili en önemli bazı hususlar; SIEM çözümlernin kullanıcı PC’leri ve
      IoT’lerden log alması, log kaçırmaması, loğlar biriktiğinde kolay rapor
      çekebiliyor olunması, korelasyonların kuruma özelleştirilmiş olması ve
      alarm false positive oranının düşük olmasıdır. Burada ürün seçiminden
      lisanslama tasarımına, kurulumundan yönetimine, false positive alarmların
      düşürülmesinden gerekli eğitimlerin verilmesine kadar birçok alanda
      müşterilerimize hizmet vermekteyiz.
    </ServiceContentLayout>
  );
};

export default SecurityTestOne;
